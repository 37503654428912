import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { NavLink } from "react-router-dom";
import {
  isMobile,
  isMobileOnly,
  isDesktop,
  isTablet,
} from "react-device-detect";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import cashbackDark from "../../../plc/src/data/img/cashback_dark.svg";
import cashbackLight from "../../../plc/src/data/img/cashback.svg";
import BgCloudLightTop from "../../../plc/src/data/img/bg_clouds_light.svg";
import BgCloudLightBottom from "../../../plc/src/data/img/bg_bottom_light.svg";
import BgCloudDarkTop from "../../../plc/src/data/img/bg_clouds_dark.svg";
import BgCloudDarkBottom from "../../../plc/src/data/img/bg_bottom_dark_1.svg";
/* import PageContentBgABCDark from "../../../abc/src/data/img/header_bg_mobile_dark.jpg";
import PageContentBgABCWhite from "../../../abc/src/data/img/header_bg_mobile_light.jpg"; */
import BanerLightABC from "./../../../abc/src/data/img/header_bg_light.jpg";
import BanerDarkABC from "./../../../abc/src/data/img/header_bg_dark.jpg";
import BanerPub from "./../../../pub/src/data/img/login-banner-pub.jpg";
import BanerNBC from "./../../../nbc/src/data/img/header_bg_nbc.jpg";
import BanerNBCMobile from "./../../../nbc/src/data/img/nbc-mobile.jpg";
import BanerYET from "./../../../yet/src/data/img/header_bg.jpg";
import BanerFUN from "./../../../fun/src/data/img/header_bg.jpg";
import BanerCAC from "./../../../cac/src/data/img/header_bg.jpg";
import BanerHYC from "./../../../hyc/src/data/img/bg_desktop.jpg";
import BanerYAK from "./../../../yak/src/data/img/crown_and_coins.svg";
import BanerLightRAC from "./../../../rac/src/data/img/banner_rac_light.jpeg";
import BanerRAC from "./../../../rac/src/data/img/bg_2.jpg";
import BanerQBC from "./../../../qbc/src/data/img/header_bg.jpg";
import { ReactComponent as MoneyBag } from "./../assets/icon-component/moneybag_icon.svg";
import { ReactComponent as CalendarRed } from "./../assets/icon-component/calendar_end.svg";
import { ReactComponent as CalendarGreen } from "./../assets/icon-component/calendar_start.svg";

export const PageContent = styled.div.attrs((props) => ({
  className: /* props.theme.skin === "abc" ? "pt-5" : */ props.bg ? "bg" : "",
}))`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  background-color: ${({ theme, about, account }) =>
    theme.skin === "abc" || theme.skin === "qbc"
      ? "var(--aboutHeaderColor)"
      : theme.skin === "rac"
      ? ""
      : theme.skin === "nbc"
      ? about
        ? "#350f46"
        : "var(--aboutHeaderColor)"
      : theme.skin === "yet" && !isMobileOnly && account
      ? "#3d90ba"
      : theme.skin === "fun"
      ? about
        ? "#44c2ce"
        : isMobileOnly
        ? "#6bc6c7"
        : "var(--aboutHeaderColor)"
      : "var(--bgcolor)"};

  min-height: ${({ profileABC, theme, ingamedep }) =>
    profileABC || theme.skin === "yak"
      ? isMobileOnly
        ? "100px"
        : "calc(100vh - 560px)"
      : ingamedep
      ? "40vh"
      : theme.skin === "rac" || theme.skin === "fun" || theme.skin === "yak"
      ? "100vh"
      : theme.skin === "yet"
      ? "calc(100vh - 260px)"
      : "calc(100vh - 560px)"};

  background-image: ${({ theme, account, nobg }) => {
    if (theme.skin === "abc") {
      if (account) {
        if (isMobile && nobg) {
          return ``;
        } else {
          if (theme.current === "dark") {
            return `url(${BanerDarkABC})`;
          } else {
            return `url(${BanerLightABC})`;
          }
        }
      } else {
        if (theme.current === "dark") {
          /*  return `url(${PageContentBgABCDark})`; */
        } else {
          /*  return `url(${PageContentBgABCWhite})`; */
        }
      }
    } else if (theme.skin === "nbc") {
      if (account) {
        if (isMobile && nobg) {
          return `url(${BanerNBCMobile})`;
        } else {
          return `url(${BanerNBC})`;
        }
      }
    } else if (theme.skin === "yet") {
      if (account) {
        if (isMobile && nobg) {
          return ``;
        } else {
          return `linear-gradient(180deg, rgba(66 149 191), rgba(22 113 158 / 0%)),
    url(${BanerYET})`;
        }
      }
    } else if (theme.skin === "fun") {
      if (account) {
        if (isMobile && nobg) {
          return ``;
        } else {
          return `url(${BanerFUN})`;
        }
      }
    } else if (theme.skin === "cac") {
      if (account) {
        if (nobg) {
          return ``;
        } else {
          return `url(${BanerCAC})`;
        }
      }
    } else if (theme.skin === "hyc") {
      if (account) {
        if (nobg) {
          return ``;
        } else {
          return `url(${BanerHYC})`;
        }
      }
    } else if (theme.skin === "qbc") {
      if (account) {
        if (nobg) {
          return ``;
        } else {
          return `url(${BanerQBC})`;
        }
      }
    } else {
      return ``;
    }
  }};
  background-repeat: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "no-repeat, no-repeat"
      : ""};
  background-position: ${({ theme, cashback }) =>
    theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "yet"
      ? cashback
        ? "bottom"
        : theme.skin === "nbc" || theme.skin === "yet"
        ? "bottom"
        : "center"
      : theme.skin === "fun"
      ? "bottom"
      : ""};
  background-size: ${({ theme, account, cashback, responsible }) => {
    if (
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun"
    ) {
      if (account) {
        if (isMobile) {
          return theme.skin === "nbc" ||
            theme.skin === "yet" ||
            theme.skin === "fun"
            ? `cover`
            : `auto`;
        } else {
          if (
            cashback ||
            theme.skin === "nbc" ||
            theme.skin === "yet" ||
            theme.skin === "fun"
          ) {
            return `100%`;
          } else {
            return `cover`;
          }
        }
      } else {
        if (isMobile) {
          return `cover`;
        } else {
          if (responsible) {
            return `cover`;
          } else {
            return `110%`;
          }
        }
      }
    } else {
      return ``;
    }
  }};
  background: ${({ theme }) =>
    theme.skin === "fun" &&
    isMobileOnly &&
    "linear-gradient(180deg, rgba(93,198,202,1) 0%, rgba(43,191,211,1) 10%)"};

  //margin: 40px 0 40px 0;

  &.bg {
    align-items: ${({ theme, about, account }) =>
      theme.skin === "rac" && (account || about) ? "" : "center"};
    min-height: ${({ theme, land, ingamedep }) =>
      theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "yet"
        ? land
          ? "100%"
          : ingamedep
          ? ""
          : "70vh"
        : ingamedep
        ? ""
        : "100vh"};
    background-position: ${({ theme, dep }) =>
      theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "yet"
        ? dep
          ? "bottom"
          : "initial"
        : theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "rac"
        ? "center"
        : theme.skin === "fun"
        ? isMobile
          ? "bottom"
          : "bottom"
        : theme.skin === "yak"
        ? isMobile
          ? "center 100px, center bottom"
          : "center 150px, center bottom"
        : theme.skin === "qbc"
        ? ""
        : "center 150px, center bottom"};
    background-repeat: no-repeat, no-repeat !important;
    background-attachment: ${({ theme }) => theme.skin === "rac" && "fixed"};
    flex-wrap: ${({ theme }) => theme.skin === "rac" && "wrap"};
    padding-top: ${({ theme, ingamedep }) =>
      theme.skin === "rac" && isMobileOnly && !ingamedep && "120px"};
    background-size: ${({ theme, dep, payments }) => {
      if (
        theme.skin === "abc" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "rac" ||
        theme.skin === "qbc"
      ) {
        if (dep || theme.skin === "rac") {
          if (payments) {
            return "100%";
          } else {
            return "cover";
          }
        } else {
          return "";
        }
      } else if (
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun"
      ) {
        if (dep) {
          return "100%";
        } else {
          return "";
        }
      } else if (theme.skin === "yak") {
        if (dep) {
          return isMobileOnly ? "70vw" : "55vw";
        } else {
          return isMobileOnly ? "70vw" : "55vw";
        }
      } else {
        if (theme.skin === "pub") {
          if (isMobile) {
            return "auto !important";
          } else {
            return "100% 110% !important";
          }
        } else {
          return "100%";
        }
      }
    }};
    background-color: ${({ theme, payments }) =>
      theme.skin === "abc" || theme.skin === "nbc"
        ? payments
          ? "var(--paymentsBg)"
          : ""
        : "var(--headerbg)"};
    background-image: ${({ theme, dep, ingamedep }) => {
      if (ingamedep && isMobile) {
        return ``;
      }
      if (theme.skin === "plc") {
        if (theme.current === "light") {
          return `url(${BgCloudLightTop}), url(${BgCloudLightBottom});`;
        } else {
          return `url(${BgCloudDarkTop}), url(${BgCloudDarkBottom});`;
        }
      } else if (theme.skin === "pub") {
        return ``;
      } else {
        if (theme.skin === "abc") {
          if (theme.current === "light") {
            return `url(${BanerLightABC})`;
          } else {
            return `url(${BanerDarkABC})`;
          }
        }
        if (theme.skin === "nbc") {
          return `url(${BanerNBC})`;
        }
        if (theme.skin === "rac") {
          if (ingamedep) {
            return ``;
          } else {
            if (theme.current === "light") {
              return `url(${BanerLightRAC})`;
            } else {
              return `url(${BanerRAC})`;
            }
          }
        }
        if (theme.skin === "yet") {
          return `url(${BanerYET})`;
        }
        if (theme.skin === "fun") {
          return `url(${BanerFUN})`;
        }
        if (theme.skin === "cac") {
          return `url(${BanerCAC})`;
        }
        if (theme.skin === "hyc") {
          return `url(${BanerHYC})`;
        }
        if (theme.skin === "yak" && !dep) {
          return `url(${BanerYAK})`;
        }
        if (theme.skin === "qbc") {
          return `url(${BanerQBC})`;
        }
      }
    }};

    background: ${({ theme, clear }) =>
      theme.skin === "pub"
        ? clear
          ? `url(${BanerPub})`
          : `linear-gradient(to bottom,rgb(0 0 0 / 70%),rgb(0 0 0 / 96%)), url(${BanerPub})`
        : /* theme.skin === "yak"
        ? `linear-gradient(42deg, rgba(79,16,134,1) 0%, rgba(109,34,184,1) 70%)`
        :  */ ""};
    background-position: ${({ theme }) => theme.skin === "rac" && "center"};
    background-repeat: ${({ theme }) => theme.skin === "rac" && "no-repeat"};
    background-size: ${({ theme }) => theme.skin === "rac" && "cover"};

    @media (max-height: 720px) {
      background-position: ${({ theme }) =>
        theme.skin === "yak" &&
        (isMobile
          ? "center 100px, center bottom"
          : "center 50px, center bottom")};
    }
  }
  &.land {
    min-height: 100%;
  }
  @media screen and (orientation: landscape) {
    padding: ${({ theme, ingamedep }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun") &&
      !isDesktop
        ? ingamedep
          ? ""
          : "100px 0"
        : ""};
  }
`;

export const PageContentWrapper = styled.div.attrs((props) => ({
  className: props.theme.skin === "plc" ? "pt-5" : "",
}))`
  margin: ${({ verification, theme }) =>
    verification /* || theme.skin === "rac" */ && "auto"};
  .form-group {
    margin-bottom: ${({ theme }) =>
      (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
      "0"};
  }
  z-index: 4;
  width: 100%;

  /* margin: ${({ theme, limits }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "bnk" ||
      theme.skin === "yet") &&
    !isMobileOnly
      ? limits
        ? ""
        : "auto"
      : theme.skin === "rac"
      ? isMobileOnly
        ? "60px auto"
        : "auto"
      : ""}; */

  @media (max-width: 767px) {
    .btn-first {
      font-size: 0.8rem;
      padding-right: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "bnk" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? ""
          : "15px"};
    }
  }
`;

export const AccountContainer = styled.div.attrs((props) => ({
  className: props.vh ? "min-vh-100 container" : "container",
}))`
  display: ${({ sports }) => sports && "flex"};
  flex-wrap: ${({ sports }) => sports && "wrap"};
  margin-bottom: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "rac") &&
    "2rem"};

  iframe {
    height: ${({ theme }) => theme.skin === "rac" && "370px !important"};
  }
  ${(props) => {
    if (props.container) {
      return `
      @media (max-width: 767px) {
        margin: ${
          props.theme.skin === "nbc" ||
          props.theme.skin === "bnk" ||
          props.theme.skin === "yet" ||
          props.theme.skin === "fun"
            ? "6rem 0"
            : props.theme.skin === "abc"
            ? "9rem auto 5rem"
            : props.theme.skin === "yak"
            ? "10rem auto"
            : props.theme.skin === "rac"
            ? "0 auto 2rem auto"
            : props.theme.skin !== "pub" &&
              props.theme.skin !== "cac" &&
              "0 auto"
        };
      }
      margin: ${
        (props.theme.skin === "pub" ||
          props.theme.skin === "cac" ||
          props.theme.skin === "hyc") &&
        isMobileOnly &&
        "8rem 0 2rem 0"
      };
      `;
    }
    if (props.mt) {
      return `
      margin-top: ${
        isMobileOnly || props.theme.skin === "abc" || props.theme.skin === "nbc"
          ? "5vh"
          : "20vh"
      };
      max-width: 1024px !important;
      color: var(--acolor);
      `;
    }
    if (props.limits) {
      return `
        max-width: 1024px !important;
        color: var(--acolor);

        .nav-link {
          margin-right: 0;
        }
        @media (max-width: 767px) {
          .btn-first {
          margin: ${
            props.theme.skin === "abc" ||
            props.theme.skin === "nbc" ||
            props.theme.skin === "bnk" ||
            props.theme.skin === "yet" ||
            props.theme.skin === "rac"
              ? "2rem 1rem"
              : props.theme.skin === "fun" ||
                props.theme.skin === "cac" ||
                props.theme.skin === "hyc" ||
                props.theme.skin === "yak"
              ? "1rem 15px"
              : "1rem auto"
          };
        }
        }
      `;
    }
    if (props.history) {
      return `
            max-width: 1024px !important;
            color: var(--acolor);
            margin: 4rem auto;
            padding-bottom: ${
              props.theme.skin === "fun" &&
              !isMobile &&
              !props.tournaments &&
              "500px"
            };
           
            
            `;
    } else {
      if (props.theme.skin === "rac") {
        return `
        /* max-width: 1024px !important;
        color: var(--acolor);

        .nav-link {
        }
        @media (max-width: 767px) {
          .btn-first {
          margin: 1rem;
        }
        } */
      `;
      } else {
        return `
            max-width: ${
              props.lending
                ? "90% !important"
                : props.tournaments
                ? ""
                : "1024px !important"
            };
            color: var(--acolor);
            /* margin-top: ${
              (props.theme.skin === "abc" ||
                props.theme.skin === "nbc" ||
                props.theme.skin === "bnk" ||
                props.theme.skin === "yet") &&
              !isMobileOnly
                ? "20vh"
                : ""
            }; */
            /* margin-bottom: ${
              (props.theme.skin === "abc" ||
                props.theme.skin === "nbc" ||
                props.theme.skin === "bnk" ||
                props.theme.skin === "yet" ||
                props.theme.skin === "fun") &&
              !isMobileOnly
                ? "4rem"
                : ""
            }; */
        
            input[type='text'] {
                font-weight: ${
                  props.theme.skin === "pub" ||
                  props.theme.skin === "cac" ||
                  props.theme.skin === "hyc"
                    ? "400"
                    : "600"
                };
                text-align: right;
                width: 60%;
                padding: 0;
                height: ${props.theme.skin === "abc" ? "40px" : "70px"};
                background-color: transparent;
            }
            .invalid-feedback {
                position: ${
                  props.theme.skin !== "pub" &&
                  props.theme.skin !== "cac" &&
                  props.theme.skin !== "hyc" &&
                  props.theme.skin !== "qbc" &&
                  "absolute"
                };
                top: ${
                  props.theme.skin !== "pub" &&
                  props.theme.skin !== "rac" &&
                  props.theme.skin !== "cac" &&
                  props.theme.skin !== "hyc" &&
                  props.theme.skin !== "qbc"
                    ? props.theme.skin === "nbc" ||
                      props.theme.skin === "plc" ||
                      props.theme.skin === "yet" ||
                      props.theme.skin === "fun" ||
                      props.theme.skin === "yak"
                      ? "3.5rem !important"
                      : ""
                    : "3rem"
                };
            }
            label {
                font-weight: ${props.theme.skin === "pub" ? "400" : "600"};
                width: 40%;
            }
            select {
                text-align: right;
                font-weight: ${props.theme.skin === "pub" ? "400" : "600"};
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: '';
                background-color: transparent;
                width: 40px !important;
                padding: 0;
                text-align-last: right;
                cursor: pointer;
                direction: rtl;
            }
            select:focus {
                background-color: transparent;
            }
        
            @media (max-width: 767px) {
                padding-left: ${
                  props.theme.skin === "abc" ||
                  props.theme.skin === "nbc" ||
                  props.theme.skin === "bnk" ||
                  props.theme.skin === "yet" ||
                  props.theme.skin === "fun" ||
                  props.theme.skin === "yak"
                    ? "0"
                    : "2rem !important;"
                }
                padding-right:${
                  props.theme.skin === "abc" ||
                  props.theme.skin === "nbc" ||
                  props.theme.skin === "bnk" ||
                  props.theme.skin === "yet" ||
                  props.theme.skin === "fun" ||
                  props.theme.skin === "yak"
                    ? "0"
                    : "2rem !important;"
                }
        
                input[type='text'] {
                height: 50px;
                font-size: 0.8rem;
                }
                select {
                width: 40px;
                height: 50px;
                text-align: right;
                text-align-last: right;
                padding: 0;
                font-size: 0.8rem;
                }
                label {
                margin-bottom: 0;
                font-size: 0.7rem;
                }

                button {
                  span {
                    font-size: ${
                      isMobileOnly ? "1rem !important" : "1.6rem !important"
                    };
                    color: ${
                      props.theme.skin === "nbc"
                        ? "#ed338c"
                        : props.theme.skin === "yet" ||
                          props.theme.skin === "fun" ||
                          props.theme.skin === "cac" ||
                          props.theme.skin === "hyc" ||
                          props.theme.skin === "yak"
                        ? "#fff"
                        : "#0554b3"
                    };
                  }
                }
            }
            @media (min-width: 768px) and (max-width: 1024px) {
                padding: 0 2rem !important;
        }
            `;
      }
    }
  }}
  input[type="text"]:disabled {
    color: var(--acolor);
    background-color: transparent;
  }
  video {
    padding: 15px;
  }
`;

export const AccountHeader = styled.h1.attrs((props) => ({
  className: props.transactions ? "text-left" : "",
}))`
  color: var(--acolor);
  text-align: left;
  font-size: ${({ theme }) => (theme.skin === "pub" ? "1.6rem" : "2rem")};
  margin: 30px 0;
  font-weight: ${(props) =>
    props.theme.skin === "pub" && props.transactions ? "500" : "900"};
  text-transform: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
    "uppercase"};

  @media (max-width: 926px) {
    margin-top: 0;
  }
`;

export const TabContent = styled.div.attrs((props) => ({
  className: props.limits ? (props.rac ? "border rounded" : "pt-5") : "",
}))`
  color: ${({ theme }) => (theme.skin === "rac" ? "#fff" : "")};
  ${(props) => {
    if (props.spanSize) {
      return `
        text-align: justify !important;
        text-justify: auto !important;
        p {
            font-size: 1.1rem !important;
            line-height: 1.6 !important;
            color: var(--acolor);
            font-weight: 500 !important;
            letter-spacing: 0.5px;
            background: ${
              props.theme.skin === "abc" ||
              props.theme.skin === "nbc" ||
              props.theme.skin === "yet" ||
              props.theme.skin === "fun" ||
              props.theme.skin === "yak"
                ? "var(--profileHeaderBgABC)"
                : ""
            };

        }
        h2 {
            color: ${props.theme.skin === "pub" ? "#9A875D" : "var(--acolor)"};
            font-weight: 800;
        }
        .text-bottom ul,
        .text-bottom ol {
            li {
            font-size: 12px;
            color: var(--tabletext);
            }
        }
        @media (max-width: 767px) {
            p {
                line-height: 1.5 !important;
                font-size: 1rem !important;
            }
        }
            `;
    } else {
      return `
        text-align: left;
        p {
            color: ${
              props.theme.skin === "rac" ? "var(--acolor)" : "var(--tabletext)"
            };
            line-height: 1.8 !important;
            font-size: 12px;
            letter-spacing: 0.5px;
        }
        .text-bottom ul,
        .text-bottom ol {
            li {
            font-size: 12px;
            ${
              props.theme.skin === "rac" ? "var(--acolor)" : "var(--tabletext)"
            };
        }
        @media (max-width: 767px) {
            p {
                line-height: 1.5 !important;
            }
        }
        `;
    }
  }}
`;

export const AccountDescription = styled.div.attrs((props) => ({
  className:
    props.timelimit && isMobileOnly && props.theme.skin === "plc" ? "mt-5" : "",
}))`
  /*   background: ${({ theme, cashback, expand }) =>
    (theme.skin === "abc" || theme.skin === "nbc") &&
    cashback &&
    (expand || !isMobileOnly ? "var(--profileHeaderBgABC)" : "")}; */
  font-size: ${({ theme }) => theme.skin === "pub" && "0.93rem"};
  line-height: 1.8;
  color: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "hyc" ||
    theme.skin === "yak"
      ? "var(--smallHeaderABC)"
      : "var(--acolor)"};
  text-align: justify;
  align-items: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    "baseline"};
  margin: ${({ theme }) =>
    isMobileOnly || theme.skin === "fun" || theme.skin === "yak"
      ? "1rem 0"
      : "4rem 0"};
  /* padding: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "yet"
      ? isMobileOnly
        ? "0 0 2rem 0"
        : "1rem 0"
      : ""}; */

  h5 {
    background: ${({ expand }) =>
      expand
        ? ""
        : !isMobileOnly
        ? ""
        : " -webkit-linear-gradient(var(--smallHeaderABC),var(--accontFadeCollor) 15%)"};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ expand }) =>
      expand ? "" : !isMobileOnly ? "" : "transparent"};
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "hyc"
        ? "var(--smallHeaderABC)"
        : "var(--acolor)"};
  }
  p {
    color: ${({ theme, about }) =>
      theme.skin === "abc" ||
      theme.skin === "hyc" ||
      (theme.skin === "yak" && !about)
        ? "var(--smallHeaderABC)"
        : theme.skin === "fun" && about
        ? "#fff"
        : "var(--acolor)"};
    font-weight: 400;
    font-size: ${({ theme }) => theme.skin === "pub" && "0.93rem"};
  }
  h3 {
    color: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "fun" ||
      theme.skin === "yak" ||
      theme.skin === "yak"
        ? "var(--aboutH2Color)"
        : "var(--acolor)"};
    font-size: 1.2rem;
    text-align: left;
    font-weight: 900;
  }
  h2 {
    color: ${({ theme, terms }) =>
      theme.skin === "abc" ||
      theme.skin === "fun" ||
      theme.skin === "yak" ||
      theme.skin === "rac"
        ? "var(--aboutH2Color)"
        : theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "yet"
        ? terms
          ? "var(--termsColor)"
          : "var(--racProfileMenuCol)"
        : "var(--acolor)"};
    text-align: left;
    font-weight: ${({ theme, about }) =>
      theme.skin === "fun" && about
        ? "400"
        : theme.skin === "rac"
        ? "600"
        : "900"};
    font-size: ${({ theme }) =>
      (theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak" ||
        theme.skin === "rac") &&
      !isMobileOnly
        ? "1.8rem"
        : "1.3rem"};
    padding: ${({ theme }) =>
      (theme.skin === "pub" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "rac" ||
        theme.skin === "qbc") &&
      "1rem 0"};
    margin: ${({ theme }) =>
      (theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      "20px 0"};
    letter-spacing: 0px;
    font-family: ${({ theme, about }) =>
      theme.skin === "fun" && about && "RavagerSansRegular"};
    text-transform: ${({ theme }) => theme.skin === "rac" && "uppercase"};
  }
  ol,
  li {
    color: ${({ theme, about }) =>
      theme.skin === "abc" || theme.skin === "hyc"
        ? "var(--smallHeaderABC)"
        : theme.skin === "fun" && about
        ? "#fff"
        : "var(--acolor)"};
    font-size: ${({ theme }) =>
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "rac" ||
      theme.skin === "qbc"
        ? "1rem"
        : "0.8rem"};
  }
  .answer::before {
    content: "&nbsp;";
    font-size: 0;
    display: block;
    line-height: 0;
  }

  .accordion {
    font-weight: 600;
    padding: 1rem 0;

    :hover {
      color: ${({ theme }) =>
        theme.skin === "pub"
          ? "#9A875D"
          : theme.skin === "yet"
          ? "#07415F"
          : theme.skin === "fun"
          ? "#1e8ea6"
          : theme.skin === "cac"
          ? "#fdb448"
          : theme.skin === "hyc"
          ? "#f4ef00"
          : theme.skin === "yak"
          ? "#fcc239"
          : theme.skin === "rac"
          ? "#f7e500"
          : "#ae0005"};
      cursor: pointer;
    }
  }
  .indent {
    color: var(--pcolor);
    margin-left: 1rem;
  }
  a {
    text-decoration: underline;
  }
  h4 {
    font-size: 0.8rem;
    text-align: ${({ theme }) =>
      (theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      "center"};
    color: ${({ theme }) => theme.skin === "cac" && "#fdb448"};
  }

  @media (max-width: 767px) {
    height: ${({ theme, expand }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "rac"
        ? expand
          ? theme.skin === "rac"
            ? ""
            : "100%"
          : "60px"
        : ""};
    overflow: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak" ||
        theme.skin === "rac") &&
      "hidden"};

    font-size: 1rem;
    line-height: 1.5;
    h3 {
      line-height: 1.5;
    }
    .indent {
      margin-left: 0.5rem;
    }
  }
`;

export const TransactionDescription = styled.div.attrs((props) => ({
  className: props.theme.skin === "plc" ? "py-3" : "",
}))`
  h3 {
    font-size: 1.3rem;
    font-weight: 600;
  }
  text-align: justify;
  ${(props) => {
    if (props.theme.skin === "plc") {
      return `
        font-size: 1.1rem;
        line-height: 1.6;
        color: var(--acolor);
        font-weight: 700;
        text-align: justify;
      
        .flex-table-cell-content {
          font-weight: 500;
        }
      
        @media (max-width: 767px) {
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 600;
          flex-wrap: wrap;
        }
      `;
    } else if (
      props.theme.skin === "abc" ||
      props.theme.skin === "nbc" ||
      props.theme.skin === "pub" ||
      props.theme.skin === "yet" ||
      props.theme.skin === "fun" ||
      props.theme.skin === "cac" ||
      props.theme.skin === "hyc" ||
      props.theme.skin === "yak"
    ) {
      return `
     
        padding: 1rem;
        p {
          font-size: ${
            props.theme.skin === "pub" ||
            props.theme.skin === "cac" ||
            props.theme.skin === "hyc"
              ? "inherit"
              : "1rem"
          };
          color: ${
            props.theme.skin === "fun"
              ? "var(--acolor)"
              : "var(--smallHeaderABC)"
          };
          font-size: ${
            props.affedd
              ? props.theme.skin === "pub"
                ? "0.93rem"
                : "1.2rem"
              : ""
          };
          font-weight: ${props.theme.skin === "pub" && "300"};
        }
        h2 {
          color: var(--acolor);
        }
        font-size: ${props.affedd ? "1.2rem" : ""}
        
      `;
    }
  }}
`;

export const StyledTabs = styled(Tabs).attrs((props) => ({
  className:
    props.theme.skin === "rac"
      ? props.limits
        ? ""
        : "my-3 p-4 border rounded"
      : "",
}))``;

export const StyledTabList = styled(TabList).attrs((props) => ({
  className:
    props.theme.skin === "rac"
      ? props.limits
        ? "nav justify-content-center py-4"
        : "nav my-3 justify-content-center"
      : props.limits
      ? "nav justify-content-around py-4 text-capitalize"
      : `nav my-3 justify-content-center ${
          (props.theme.skin !== "abc" || props.theme.skin !== "nbc") && "mt-5"
        } py-3`,
}))`
  background: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun") &&
    "var(--profileCashbackBgABC)"};
`;

export const StyledTab = styled(Tab).attrs((props) => ({
  className: "nav-link",
}))`
  transition: background-color 0.2s ease-in;
  font-family: ${({ theme }) => theme.skin === "pub" && "Ancho"};
  font-weight: ${({ theme }) => theme.skin === "pub" && "500"};
  font-size: ${({ theme }) => theme.skin === "pub" && "0.83rem"};
`;

export const StyledTabPanel = styled(TabPanel).attrs((props) => ({
  className: `flex-table ${
    props.theme.skin === "rac"
      ? props.racgdpr && isMobile
        ? "justify-content-around"
        : ""
      : ""
  }`,
}))``;

export const TextBottom = styled.div.attrs((props) => ({
  className: props.open ? "open" : "",
}))`
  text-shadow: ${({ theme }) =>
    (theme.skin === "yet" || theme.skin === "nbc") &&
    !isMobileOnly &&
    "white 0px 0px 1px"};
  font-size: ${({ theme }) => (theme.skin === "pub" ? "0.93rem" : "inherit")};
  font-weight: ${({ theme }) => theme.skin === "pub" && 300};
  min-height: 350px;
  line-height: 1.8;
  color: ${({ theme }) => (theme.skin === "abc" ? "#0554b3" : "var(--acolor)")};
  padding-top: ${isMobileOnly ? "1rem" : "4rem"};

  ol {
    padding: 2rem 1rem 4rem 1rem;

    li {
      text-align: ${({ theme }) =>
        (theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "yak") &&
        "left"};
    }
  }
  .bullet {
    margin-left: 2rem;
    li {
      display: list-item;
      list-style-type: circle !important;
    }
  }
  ul {
    padding-left: 0;
    li {
      display: inline-block;
      margin-right: 5px;
      font-size: ${({ theme }) =>
        theme.skin === "rac" ? "0.8rem" : "inherit"};
      color: ${({ theme }) => theme.skin === "rac" && "var(--tabletext)"};
    }
  }
  &.open {
    margin-bottom: ${({ theme }) => theme.skin !== "rac" && "100px"};
  }
  h2 {
    color: var(--acolor);
    text-align: ${({ theme }) => theme.skin === "rac" && "center"};
    font-weight: ${({ theme }) => (theme.skin === "rac" ? "600" : "900")};
    text-transform: ${({ theme }) => theme.skin !== "rac" && "uppercase"};
    font-size: ${({ theme }) => (theme.skin === "rac" ? "1.2rem" : "1.3rem")};
  }
  h3 {
    text-align: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "rac" ? "center" : "left"};
    color: var(--acolor);
    margin: ${({ theme }) => (theme.skin === "rac" ? "" : "1rem 0")};
    font-size: ${({ theme }) => (theme.skin === "rac" ? "0.9rem" : "1.2rem")};
  }
  @media (max-width: 926px) {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;

    h3 {
      line-height: 1;
    }
    ul {
      li {
        /* font-size: 1rem !important; */
      }
    }

    ul,
    ol {
      li {
        text-align: ${({ theme }) => theme.skin === "rac" && "left"};
        font-size: 12px;
        color: ${({ theme }) =>
          theme.skin === "abc"
            ? "#0554b3"
            : theme.skin !== "rac"
            ? "var(--tabletext)"
            : ""};
      }
    }
  }
`;

export const StyledCol = styled.div.attrs((props) => ({
  className: "col",
}))`
  padding: ${(props) => (props.spanPadding ? "0 !important" : "")};
  /* border: ${({ theme }) =>
    theme.skin === "rac" && "1px solid var(--inputbordercolorABC)"}; */
`;

export const StyledAccontH1 = styled.h1`
  color: var(--acolor);
  text-align: left;
  font-size: 2rem;
  margin: 30px 0;
  font-weight: 900;

  @media (max-width: 926px) {
    margin-top: 0;
  }
`;

export const AccountCustomFormInline = styled.div.attrs({
  className: "form-inline",
})`
  margin: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "0 0 2rem 0"
      : "4rem 0"};
  padding: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "0 0 1rem 0"
      : ""};
  background: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc"
      ? "var(--profileHeaderBgABC)"
      : ""};

  label {
    justify-content: left;
  }
  @media (max-width: 767px) {
    margin: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "0"
        : "4rem 0 0 0"};
  }
`;

export const StyledUserName = styled.div`
  font-size: 2rem;
  color: ${({ theme }) =>
    theme.skin === "abc"
      ? "var(--aboutH2Color)"
      : theme.skin === "pub"
      ? "#9A875D"
      : "var(--acolor)"};
  font-weight: 600;

  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
`;

export const MyProfileButton = styled.button.attrs({
  className: "btn btn-first col-md-2 ml-auto",
})`
  width: 100px !important;
  background-color: ${({ theme }) => (theme.skin === "abc" ? "#EAEBEF" : "")};
  color: ${({ theme }) => (theme.skin === "abc" ? "#0554b3" : "")};
  border: ${({ theme }) => (theme.skin === "pub" ? "3px solid #fff" : "")};
  border-radius: ${({ theme }) => (theme.skin === "pub" ? "0.3rem" : "")};

  :hover {
    border-color: ${({ theme }) => (theme.skin === "pub" ? "#9A875D" : "")};
    color: ${({ theme }) => (theme.skin === "abc" ? "#fff" : "")};
  }
`;

export const AccountCol = styled.div.attrs((props) => ({
  className: "col-sm",
}))`
  text-transform: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak") &&
    "uppercase"};
  input {
    text-transform: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak") &&
      "uppercase"};
  }
  select {
    text-transform: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak") &&
      "uppercase"};
  }
`;
export const StyledFormRow = styled.div.attrs((props) => ({
  className: `form-row ${props.extra && props.extra}`,
}))`
  max-width: ${({ zignsecFrame }) => zignsecFrame && "535px !important"};
  margin: ${({ zignsecFrame }) => zignsecFrame && "auto !important"};
  padding-top: ${({ zignsecFrame, theme }) =>
    zignsecFrame &&
    !isMobileOnly &&
    (theme.skin === "nbc" || theme.skin === "yet") &&
    "100px"};
  ${(props) => {
    if (props.nbcstepone && isMobileOnly) {
      return `
      .form-group {
        padding-left: 0;
        padding-right: 0;
      }
      `;
    }
  }}
  .form-inline {
    label {
      justify-content: left;
    }
  }
  .form-group.col-md-12 {
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
  @media (max-width: 767px) {
    margin: 0rem !important;
  }
`;

export const BirthdayWrapper = styled.div`
  color: ${({ theme }) =>
    theme.skin === "abc"
      ? "#0554b3"
      : theme.skin === "rac"
      ? "var(--labelcolor)"
      : theme.skin === "fun"
      ? "var(--acolor)"
      : "#fff"};
  padding-top: ${({ theme }) => (theme.skin === "rac" ? "1rem" : "0.8rem")};
  height: 35px;
  line-height: ${({ theme }) => theme.skin === "rac" && "12px"};
  font-weight: ${({ theme }) => theme.skin === "rac" && "600"};
  text-transform: ${({ theme }) => theme.skin === "rac" && "capitalize"};
  font-size: ${({ theme }) => theme.skin === "rac" && "12px"};
`;

export const StyledFormWrapper = styled.div.attrs((props) => ({
  className: props.mobileLandscape
    ? "form-inline text-left col-sm mx-2"
    : "form-inline text-left col-sm",
}))`
  text-transform: ${({ theme }) =>
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak" ||
    theme.skin === "rac" ||
    theme.skin === "qbc"
      ? "capitalize"
      : "uppercase"};
  input {
    text-transform: ${({ theme }) =>
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "rac" ||
      theme.skin === "qbc"
        ? "capitalize"
        : "uppercase"};
    width: ${({ theme }) => theme.skin === "rac" && "100% !important"};
  }
  label {
    color: ${({ theme }) => theme.skin === "rac" && "var(--labelcolor)"};
    font-weight: ${({ theme }) => theme.skin === "rac" && "600"};
  }
  ${(props) => {
    if (props.editButton) {
      return `
        border-bottom: ${props.theme.skin === "rac" ? "" : "1px solid #ae0005"};
        input {
          border: ${props.theme.skin === "rac" && "1px solid #f7e500"};
        }
        padding: 0 !important;

        @media (max-width: 767px) {
            /* height: 50px; */
        }
    `;
    }
  }}
  border-bottom: ${(props) =>
    props.spanborderbottom
      ? props.theme.skin === "abc" || props.theme.skin === "rac"
        ? ""
        : props.theme.skin === "qbc"
        ? "1px solid #8564362e"
        : "1px solid var(--footerBorder)"
      : ""};
  padding: ${(props) =>
    props.spanborderbottom ||
    props.theme.skin === "fun" ||
    props.theme.skin === "cac" ||
    props.theme.skin === "hyc" ||
    props.theme.skin === "yak" ||
    props.theme.skin === "rac" ||
    props.theme.skin === "qbc"
      ? "0 !important"
      : ""};

  label {
    justify-content: left !important;
  }

  @media (max-width: 767px) {
    margin: 0rem !important;
  }
`;

export const StyledFormLastRow = styled.div.attrs((props) => ({
  className: "form-inline text-left col-sm",
}))`
  text-transform: ${({ theme }) =>
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak" ||
    theme.skin === "rac" ||
    theme.skin === "qbc"
      ? "capitalize"
      : "uppercase"};
  label {
    justify-content: left !important;
  }
  select {
    text-transform: ${({ theme }) =>
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "rac" ||
      theme.skin === "qbc"
        ? "capitalize"
        : "uppercase"};
    height: ${({ theme }) =>
      theme.skin === "abc"
        ? "40px"
        : theme.skin === "pub" ||
          theme.skin === "plc" ||
          theme.skin === "cac" ||
          theme.skin === "hyc"
        ? isMobileOnly
          ? ""
          : "70px"
        : theme.skin === "rac"
        ? ""
        : "70px !important"};
  }
  ${(props) => {
    if (props.editButton) {
      return `
            border-bottom: ${
              props.theme.skin === "abc" || props.theme.skin === "rac"
                ? ""
                : props.theme.skin === "qbc"
                ? "1px solid #8564362e"
                : "1px solid var(--footerBorder)"
            };
            padding: 0 !important;

            label {
                width: calc(100% - 40px);
                color: ${props.theme.skin === "rac" && "var(--labelcolor)"};
                font-weight: ${props.theme.skin === "rac" && "600"};
            }
            `;
    } else {
      if (!props.doubleIP || !props.bonusBlocked) {
        return `
        border-bottom: ${props.theme.skin === "rac" ? "" : "1px solid #ae0005"};
        select {
          border: ${props.theme.skin === "rac" && "1px solid #f7e500"};
        }
            padding: 0 !important;
            
            label {
                width: calc(100% - 40px);
            }
            @media (max-width: 767px) {
            /* height: 50px; */
            }

            `;
      } else {
        return `
            label {
                width: calc(100% - 40px);
            }
            border-bottom: ${
              props.theme.skin === "abc" ? "" : "1px solid var(--footerBorder)"
            };
            padding: 0 !important;

            @media (max-width: 767px) {
            height: 50px;
            }
            `;
      }
    }
  }}
`;
export const CashbackCountdown = styled.div`
  font-size: 1.3vh;
  background: ${({ theme }) =>
    theme.skin === "plc"
      ? theme.current === "light"
        ? `url(${cashbackLight})`
        : `url(${cashbackDark})`
      : ""};
  background-repeat: no-repeat;
  background-position: center;
  color: var(--labelcolor);
  font-weight: 700;
  border-radius: 8px;
  padding: 4rem 0;
  margin: 0 auto;
  width: 600px;

  .btn-activate {
    text-transform: capitalize;
    height: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "50px"
        : "70px"};
    font-size: ${({ theme }) => (theme.skin === "pub" ? "0.8rem" : "1.1rem")};
    /* width: ${({ theme }) => (theme.skin === "plc" ? "" : "70%")}; */
    &:disabled:hover {
      border-color: inherit;
      background-color: ${({ theme }) =>
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "#fff"
          : ""};
      color: ${({ theme }) => (theme.skin === "nbc" ? "#350e46" : "")};
    }
  }
  @media (max-width: 576px) {
    width: 100%;
    padding: ${({ theme }) => (theme.skin !== "plc" ? "2rem 0" : "150px 0px")};

    .btn-activate {
      text-transform: capitalize;
      height: 40px;
      font-size: 0.6rem;
      width: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "60%"
          : ""};
    }
  }
`;

export const BackgroundWrapperCashback = styled.div`
  width: 100%;
  border: ${({ theme }) =>
    theme.skin === "rac" && "1px solid var(--bordercolor)"};
  border-top: ${({ theme }) =>
    theme.skin !== "rac" && "2px solid var(--cashbackBorderColor)"};
  border-bottom: ${({ theme }) =>
    theme.skin !== "rac" && "2px solid var(--cashbackBorderColor)"};
`;

export const StyledTextCenter = styled.div.attrs((props) => ({
  className: `text-center ${props.padding}`,
}))``;

export const StyledClock = styled.div`
  text-align: center;
  text-transform: ${({ theme }) =>
    theme.skin === "abc" ? "uppercase" : "capitalize"};
  font-weight: ${({ theme }) => (theme.skin === "pub" ? "400" : "500")};
  color: ${({ theme }) =>
    theme.skin === "abc" ? "#0554b3" : "var(--timeLeft)"};
`;

export const StyledClockWrapper = styled.div`
  color: ${({ theme }) => {
    if (theme.skin === "abc") return `#0554b3`;
    if (theme.skin === "plc") return `var(--footerh)`;
    if (theme.skin === "pub") return `#9A875D`;
  }};
  font-family: ${({ theme }) => {
    if (theme.skin === "pub") return `Ancho`;
  }};
  font-weight: ${({ theme }) => {
    if (theme.skin === "pub") return `500`;
    else return "800";
  }};
`;

export const DepositAndCashbackWrapper = styled.div.attrs({
  className: "row justify-content-between",
})`
  width: ${({ theme }) => (theme.skin === "rac" ? "" : "70%")};
  margin: 0 auto;
  font-weight: 500;
  color: ${({ theme }) => (theme.skin === "abc" ? "#0554b3" : "var(--acolor)")};
  padding-bottom: 0.5rem;
`;

export const TotalDepositWrapper = styled.div.attrs({
  className: "text-left align-self-end col-md-3 col-sm-12",
})``;

export const DepositAndCashback = styled.div`
  font-size: ${({ theme }) => (theme.skin === "rac" ? "30px" : "20px")};
  font-weight: 900 !important;
  color: ${({ theme }) => theme.skin === "pub" && "#9A875D"};

  @media (max-width: 576px) {
    font-size: 5vw;
  }
`;

export const TotalCashbackWrapper = styled.div.attrs({
  className: "text-right col-md-4 col-sm-12",
})``;

export const RedeemCashback = styled.div.attrs({
  className: "col-6 align-self-center text-uppercase mx-auto",
})``;

export const CashbackButton = styled.button.attrs((props) => ({
  className: isMobileOnly
    ? props.theme.skin === "abc"
      ? "mt-5 btn btn-first w-200 btn-activate"
      : "btn btn-first w-200 btn-activate btn-border"
    : "mt-5 btn btn-first w-200 btn-activate btn-border",
}))`
  font-size: ${({ theme }) => theme.skin === "pub" && "0.8rem"};
`;

export const ChangePasswordWrapper = styled.div.attrs((props) => ({
  className: `col-lg-6 col-md-8 col-sm-12 offset-lg-3 offset-md-2 ${
    props.theme.skin === "abc" && isMobile ? "mt-5" : ""
  }`,
}))``;

export const ChangeInputWrapper = styled.div.attrs((props) => ({
  className: props.button
    ? "form-group text-center col-md-10"
    : "form-group text-left col-md-10",
}))`
  margin: 0 auto;
  float: none;
  margin-bottom: 1.5rem !important;
`;

export const ChangePassButton = styled.button.attrs({
  className: "btn btn-first col-md-7 my-3",
})`
  ${(props) => {
    if (props.pub) {
      return `
        border: ${props.goldenborder ? "2px solid #9A875D" : "2px solid #fff"};
        border-radius: 0.3rem;
        cursor: pointer;
  
        &:hover {
          border-color: ${props.goldenborder ? "#fff" : "#9A875D"};
        }
        &:disabled {
          &:hover {
            border-color: #fff;
          }
        }
        `;
    }
  }}
`;

export const InvalidFeedback = styled.div.attrs({
  className: "invalid-feedback",
})`
  top: ${({ abc }) => abc && "1.7rem !important"};
  position: ${({ reg }) => (reg ? "absolute" : "absolute")};
  display: ${({ reg }) => (reg ? "block" : "")};
  left: ${({ tel }) => (tel ? "15px" : "")};
  bottom: ${({ reg, tel }) => (reg ? (tel ? "3rem" : "-1rem") : "")};

  @media (max-width: 767px) {
    bottom: ${({ reg, tel }) => (reg ? (tel ? "2.5rem" : "-1rem") : "")};
    left: ${({ tel }) => (tel ? "20px" : "")};
  }
`;

export const TransactionsButtonWrapper = styled.div.attrs((props) => ({
  className: `d-flex justify-content-between ${
    props.theme.skin === "plc" ? "border-bottom" : ""
  } py-4`,
}))`
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--acolor);
  font-weight: 700;
  text-align: justify;
  background: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "var(--profileHeaderBgABC)"
      : ""};
  align-items: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    "baseline"};
  border-bottom: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
    "2px solid var(--progressBar)"};
  margin-top: ${!isMobileOnly && "4rem"};

  .flex-table-cell-content {
    font-weight: 500;
  }

  @media (max-width: 767px) {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
    flex-wrap: wrap;
    align-items: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "baseline"
        : ""};
  }
`;

export const TransactionsDescription = styled.span`
  font-weight: 300;
  font-size: ${({ theme }) => theme.skin === "pub" && "0.93rem"};
  justify-content: ${({ theme }) => theme.skin === "pub" && "center"};
  display: ${({ theme }) => theme.skin === "pub" && "flex"};
`;

export const TransactionButton = styled.button.attrs({
  className: "btn btn-first",
})`
  width: 140px !important;
  font-size: 0.8rem !important;
  border: ${({ theme }) => (theme.skin === "pub" ? "3px solid #fff" : "")};
  border-radius: ${({ theme }) => (theme.skin === "pub" ? "0.3rem" : "")};
  margin-left: 1rem;

  :hover {
    border-color: ${({ theme }) => (theme.skin === "pub" ? "#9A875D" : "")};
  }

  @media (max-width: 767px) {
    margin: 1rem 0 0 0;
  }
`;

export const GameTransactionWrapper = styled.div.attrs({
  className: "flex-table-row justify-content-center mt-3 mb-3",
})`
  align-items: ${({ theme }) =>
    (theme.skin === "plc" ||
      theme.skin === "pub" ||
      theme.skin === "rac" ||
      theme.skin === "cac" ||
      theme.skin === "hyc") &&
    "baseline"};
`;

export const DatePickerWrapper = styled.div.attrs({
  className: "form-group d-flex flex-column col-md-4",
})``;

export const StyledDatePicker = styled(DatePicker).attrs((props) => ({
  className: props.theme.skin === "plc" ? "form-control" : "form-control",
}))`
  /* background-color: transparent; */
  input {
    background-color: transparent !important;
  }
  .react-calendar {
    background-color: ${({ theme }) =>
      theme.skin === "abc" ? "var(--inputbgABC)" : "var(--inputbg)"};
    border: none;
    -webkit-box-shadow: 0px 0px 10px -2px var(--headerbg);
    box-shadow: 0px 0px 10px -2px var(--headerbg);
    border-radius: 0.5rem;
    margin-top: 1rem;

    button:hover {
      background-color: #1087ff;
    }
  }
  .react-date-picker__inputGroup {
    text-align: center;
  }
  .react-calendar__month-view {
    padding-bottom: 0.5rem;
  }
  // Frame style(wrapper)
  .react-date-picker__wrapper {
    input {
      color: var(--acolor);
    }
    border: none;
    align-items: center;
    height: 100%;
  }
  .react-calendar__calendar {
    inset: 100% auto auto 0px !important;
  }
  // SVG icon for calendar
  .react-date-picker__button {
    svg {
      stroke: var(--acolor) !important;
    }
  }
  .react-calendar__button {
    padding: 0 0px 0 0;
  }
  // Select range that hovers over dates style
  .react-calendar--selectRange .react-date-picker__tile--hover {
    background-color: #1087ff;
    color: #fff;
  }
  .react-calendar__navigation {
    button {
      color: var(--acolor);
      background-color: transparent;
      :focus {
        background-color: #1087ff;
      }
      :hover {
        background-color: #1087ff;
      }
    }
  }
  .react-calendar__month-view__days__day--weekend:disabled {
    color: gray !important;
  }
  .react-calendar__month-view__days__day {
    color: var(--acolor);
  }
  .react-calendar__month-view__days__day--weekend {
    color: red !important;
  }
  .react-calendar__tile {
    color: var(--acolor);
    border: none;
    background-color: var(--inputbgABC);

    :hover {
      background-color: #1087ff;
    }
  }
  // Month, Year, Decate View style
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    text-transform: capitalize;
    border: none;
  }
  // Month in navigation style
  .react-calendar__navigation__label__labelText {
    text-transform: capitalize;
  }
  // Month in navigation HOVER style
  .react-calendar__tile__navigation__label:hover {
    background-color: #1087ff;
  }
  //Title style dissabled
  .react-calendar__tile:disabled {
    background-color: transparent;
    color: gray;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const StyledDateRangePicker = styled(DateRangePicker).attrs((props) => ({
  className: "custom-input number",
}))`
  width: 350px;
  border: ${({ theme }) =>
    theme.skin === "rac" ? "1px solid var(--inputbordercolorABC)" : "none"};
  color: var(--acolor);
  ${(props) => {
    if (props.choseDate) {
      return `
      .react-calendar:after {
        content: "${props.choseDate}";
        color: gray;
      }
      
      `;
    }
  }}

  // Global styled for callendar
  .react-calendar {
    background-color: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "fun"
        ? "var(--inputbgABC)"
        : theme.skin === "rac"
        ? "var(--bgcolor)"
        : "var(--customInputColor)"};
    border: none;
    -webkit-box-shadow: 0px 0px 10px -2px var(--headerbg);
    box-shadow: 0px 0px 10px -2px var(--headerbg);
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding-bottom: 0.5rem;
    button:hover {
      background-color: #1087ff;
      color: ${({ theme }) => theme.skin === "rac" && "#fff"};
    }
  }
  .react-calendar__month-view {
    padding-bottom: 0.5rem;
  }
  // Frame style(wrapper)
  .react-daterange-picker__wrapper {
    border: none;
    align-items: center;
    height: 100%;
  }
  .react-daterange-picker__calendar {
    inset: 100% auto auto 0px !important;
  }
  // SVG icon for calendar
  .react-daterange-picker__calendar-button {
    svg {
      stroke: var(--acolor) !important;
    }
  }
  .react-calendar__month-view__days__day {
    color: var(--acolor);
  }
  .react-calendar__month-view__days__day--weekend:disabled {
    color: gray !important;
  }
  .react-calendar__month-view__days__day--weekend {
    color: red !important;
  }
  .react-calendar__tile {
    color: var(--acolor);
    border: none;
    background-color: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "fun"
        ? "var(--inputbgABC)"
        : "var(--customInputColor)"};
    padding: 10px;

    :hover {
      background-color: #1087ff;
    }
  }
  .react-daterange-picker__button {
    padding: 0 0px 0 0;
  }
  // Select range that hovers over dates style
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #1087ff;
    color: #fff;
  }
  .react-daterange-picker__inputGroup__input {
    color: var(--acolor);
  }
  .react-calendar__navigation {
    button {
      border: none;
      color: var(--acolor);
      background-color: transparent;
      padding: 10px;
      :focus {
        background-color: #1087ff;
      }
      :hover {
        background-color: #1087ff;
      }
    }
  }
  // Month, Year, Decate View style
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    text-transform: capitalize;
  }
  // Month in navigation style
  .react-calendar__navigation__label {
    text-transform: capitalize;
  }
  // Month in navigation HOVER style
  .react-calendar__navigation__label:hover {
    background-color: #1087ff;
  }
  //Title style dissabled
  .react-calendar__tile:disabled {
    background-color: transparent;
    color: gray;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const GameTransactionButtonWrapper = styled.div.attrs({
  className: "form-group col-md-3",
})``;

export const GameTransactionButton = styled.div.attrs({
  className: `btn btn-first w-100 ${isMobileOnly && "mt-5"}`,
})`
  border: ${({ theme }) => (theme.skin === "pub" ? "3px solid #fff" : "")};
  border-radius: ${({ theme }) => (theme.skin === "pub" ? "0.3rem" : "")};

  :hover {
    border-color: ${({ theme }) => (theme.skin === "pub" ? "#9A875D" : "")};
  }
`;

export const GameHistoryWrapper = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "plc"
      ? "flex-table-row flex-table-row-header border-bottom"
      : "flex-table-row flex-table-row-header",
}))`
  border-bottom: ${({ theme }) =>
    (theme.skin === "pub" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "rac") &&
    theme.skin === "rac"
      ? "1px solid var(--progressBar)"
      : "2px solid var(--progressBar)"};
  ${(props) => {
    if (props.theme.skin === "plc") {
      return `
            font-size: 1.1rem;
            line-height: 1.6;
            color: var(--acolor);
            font-weight: 700;
            text-align: justify;

            .flex-table-cell-content {
                font-weight: 500;
            }`;
    }
  }}
`;

export const TableHeader = styled.div.attrs((props) => ({
  className: `flex-table-cell title-content ${props.col}`,
}))`
  padding: ${({ theme }) => (theme.skin === "plc" ? "0 !important" : "")};
  text-align: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    isTablet
      ? "right !important"
      : ""};
  color: ${({ theme }) => theme.skin === "pub" && "#9A875D !important"};

  @media (max-width: 767px) {
    ${(props) => {
      if (props.theme.skin === "plc") {
        return `
                .flex-table-cell-heading {
                    flex: 0;
                    }
                `;
      }
    }}
  }
`;

export const TransactionNotFound = styled.div.attrs((props) => ({
  className: `flex-table-row justify-content-center p-3 ${
    props.theme.skin === "plc" ? "border-bottom" : ""
  }`,
}))`
  ${(props) => {
    if (props.theme.skin === "plc") {
      return `
            font-size: 1.1rem;
            line-height: 1.6;
            color: var(--acolor);
            font-weight: 700;
            text-align: justify;

            .flex-table-cell-content {
                font-weight: 500;
            }
            @media (max-width: 767px) {
                font-size: 1rem;
                line-height: 1.5;
                font-weight: 600;
                flex-wrap: wrap;
            }
            `;
    }
    if (props.theme.skin === "rac") {
      return `
      color: var(--acolor);`;
    }
  }}
`;

export const StyledTableRow = styled.div.attrs((props) => ({
  className: `flex-table-row ${
    props.theme.skin === "plc" ||
    props.theme.skin === "pub" ||
    props.theme.skin === "cac" ||
    props.theme.skin === "hyc"
      ? "border-bottom"
      : ""
  }`,
}))`
  ${(props) => {
    if (props.theme.skin === "plc" || props.financial) {
      return `
            font-size: ${props.theme.skin === "pub" ? "0.933rem" : "1.1rem"};
            line-height: 1.6;
            color: var(--acolor);
            font-weight: 700;
            text-align: justify;

            .flex-table-cell-content {
                font-weight: ${props.theme.skin === "pub" ? "600" : "500"};
                font-size: ${props.theme.skin === "pub" && "0.933rem"}
            }
            @media (max-width: 767px) {
                font-size: 1rem;
                line-height: 1.5;
                font-weight: 600;
                flex-wrap: wrap;
            }
            `;
    }
  }};
`;

export const StyledTransactionWrapper = styled.div`
  min-height: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun") &&
    "470px"};
`;

export const StyledTableCell = styled.div.attrs((props) => ({
  className: `flex-table-cell ${props.cell} col-sm-12 ${props.col}`,
}))`
  ${(props) => {
    if (props.theme.skin === "plc") {
      return `
            padding: 0 !important;
  
            @media (max-width: 767px) {
                .flex-table-cell-heading {
                    flex: 0;
                }
            }
              `;
    }
  }}
  @media (max-width: 767px) {
    .flex-table-cell-heading {
      flex: 0;
    }
  }
`;

export const StyledTableContent = styled.div.attrs({
  className: "flex-table-cell-content text-capitalize",
})`
  ${(props) => {
    if (props.description) {
      return `
            font-size: ${props.theme.skin === "pub" ? "0.933rem" : "1.1rem"};
            line-height: 1.6;
            color: var(--acolor);
            font-weight: 700;
            text-align: justify;

            .flex-table-cell-content {
                font-weight: 500;
            }
            @media (max-width: 767px) {
                font-size: 1rem;
                line-height: 1.5;
                font-weight: 600;
                flex-wrap: wrap;
            }
            `;
    } else if (props.right) {
      return `
      text-align: right !important;
      `;
    } else if (props.left) {
      return `
      text-align: left !important;

      @media(max-width: 767px){
        text-align: right !important;
      }
      `;
    }
  }};
`;

export const StyledTableHeading = styled.div.attrs({
  className: "flex-table-cell-heading",
})``;

export const TextPage = styled.div.attrs((props) => ({
  className: props.terms ? "terms" : "",
}))`
  max-width: 100%;
  line-height: 14px;
  color: var(--acolor);
  text-align: center;
  //text-align-last: left;
  margin: ${({ newsPub, theme }) =>
    newsPub
      ? "0rem auto"
      : theme.skin === "nbc" ||
        ((theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "yak") &&
          isMobile)
      ? "0 auto"
      : "3rem auto"};
  /* padding-left: 40px;
    padding-right: 40px; */

  a {
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    text-align: center;
    padding: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "bnk" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "10px 6px !important"
        : ""};
    color: ${({ theme, news }) =>
      theme.skin === "abc"
        ? news
          ? ""
          : "#96a0b5 !important"
        : theme.skin === "fun" && news
        ? "#fff !important"
        : ""};
    background-color: ${({ theme, news }) =>
      theme.skin === "yet"
        ? "#07415F"
        : theme.skin === "fun" && news
        ? "#1e8ea6"
        : ""};
    :hover {
      color: ${({ theme, news }) =>
        theme.skin === "abc" ? (news ? "" : "#CF1F2C !important") : ""};
    }
  }
  .flex {
    display: flex;
    margin-bottom: 40px;
    align-items: flex-end;
  }

  ol,
  ul {
    padding-left: 0;
    list-style-position: inside;
  }
  li {
    display: list-item;
  }
  &.terms {
    ol,
    ul {
      margin-left: 20px;
      list-style-position: outside;
    }
    li {
      display: list-item !important;
      padding: 5px 10px;
    }
    ol:before {
      font-size: 15px;
    }
  }
  li,
  p {
    font-size: 14px;
    font-weight: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "nbc" ? "400" : "500"};
    line-height: 25px;
    color: ${({ theme }) =>
      theme.skin === "abc" ? "#96a0b5" : "var(--acolor)"};
    text-align: justify;
    display: table-row;
    text-align-last: left;

    &.question {
      //for faq page
      font-weight: 600;
    }
  }
  h2 {
    display: flex;
    flex-direction: row;
    width: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "100%"
        : ""};
    margin-top: 50px;
    font-size: 25px;
    font-weight: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "nbc" ? "400" : "700"};
    line-height: 30px;
    text-align: left;
    color: ${({ theme, payments }) =>
      theme.skin === "abc"
        ? payments
          ? "var(--aboutH2Color)"
          : "var(--welcomBonusFont)"
        : "var(--footerh)"};
    text-transform: uppercase;
    margin-bottom: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "40px"
        : "40px"};
  }
  h2:after {
    content: "";
    flex: 1 1;
    border-bottom: ${({ theme }) =>
      theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak"
        ? ""
        : "1px solid"};
    margin-bottom: 0;
    color: ${({ theme }) =>
      theme.skin === "nbc"
        ? "#977ea2"
        : theme.skin === "yet"
        ? "#fff"
        : "#0554b3"};
  }
  @media (min-width: 576px) {
    h2 {
      font-size: 30px;
      margin-top: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? ""
          : "140px"};
      letter-spacing: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? ""
          : "7px"};
    }
    h3 {
      color: ${({ theme }) =>
        theme.skin === "abc" ? "var(--aboutH2Color)" : "var(--acolor)"};
      font-size: 1.6rem;
      padding: 2rem;
    }
    li,
    p {
      font-size: 20px;
      font-weight: 500;
      line-height: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "30px"
          : "35px"};
      color: ${({ theme }) =>
        theme.skin === "abc" ? "#96a0b5" : "var(--acolor)"};
      text-align: justify;
    }
  }
  @media (max-width: 767px) {
    padding: ${({ newsPub }) => (newsPub ? "0.5rem" : "1rem")};
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 2rem;
    max-width: 100%;
  }
  /* @media (min-width: 1680px) {
    padding: 0px;
    margin-bottom: 100px;

    li,
    p {
      .question {
        line-height: 60px;
      }
    }
  } */
`;

export const PreviousBonusWrapper = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "plc"
      ? "flex-table-row p-3 border-bottom"
      : `flex-table-row p-3 `,
}))`
  justify-content: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    isMobileOnly &&
    "center"};

  @media screen and (orientation: landscape) {
    justify-content: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak") &&
      isMobileOnly &&
      "inherit"};
  }
`;

export const BonusDescription = styled.div.attrs({
  className:
    "col-xs-12 col-md-8 col-lg-8 d-flex flex-column justify-content-between description my-3",
})`
  text-align: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak" ||
      theme.skin === "qbc") &&
    "left"};
`;

export const StyledProgress = styled.div.attrs({
  className: "my-3",
})`
  display: flex;
  height: $progress-height;
  overflow: hidden; // force rounded corners by cropping it
  @include font-size($progress-font-size);
  background-color: $progress-bg;
  @include border-radius($progress-border-radius);
  @include box-shadow($progress-box-shadow);
`;

export const BonusCol = styled.div.attrs((props) => ({
  className: props.smaller
    ? "col-xs-12 col-md-4 col-lg-4"
    : "col-xs-12 col-md-12 col-lg-9",
}))``;

export const AboutContainer = styled.div.attrs((props) => ({
  className: "d-flex",
}))``;

export const StyledPageHeaderWrapper = styled.div.attrs({
  className: "d-inline-block bg-warning",
})`
  width: 50%;
  text-align: ${({ right }) => (right ? "right" : "")};

  @media (max-width: 767px) {
    width: 100%;
  }
  a:hover {
    background-color: ${({ theme }) => theme.skin === "cac" && "transparent"};
  }
  .btn {
    background-color: transparent;
  }
`;
export const StyledPageHeader = styled.h3`
  text-align: left;
  font-size: 2rem;
  height: 100%;
  font-weight: 900;
  color: var(--acolor) !important;
  /* font-family: ${({ theme }) => theme.skin !== "pub" && "Catamaran"}; */
  padding: 0.75rem 0;
  margin: 0;
  text-transform: ${({ theme }) => theme.skin === "pub" && "uppercase"};
  line-height: inherit;

  @media (max-width: 767px) {
    padding: 2rem 0;
    font-size: 1.7rem !important;
  }
`;

export const StyledColOffset = styled.div.attrs({
  className: "col-md-6 col-sm-12 offset-md-3",
})``;
export const CashbackHeader = styled.h1`
  color: var(--acolor);
  text-align: left;
  font-size: 2rem;
  margin: 30px 0;
  font-weight: 900;
  text-transform: ${({ theme }) => theme.skin === "pub" && "uppercase"};

  @media (max-width: 926px) {
    margin-top: 0;
  }
`;

export const StyledAccountUl = styled.ul.attrs({
  className: "nav mt-3 justify-content-between",
})``;

export const StyledAccountLi = styled.li.attrs({
  className: "nav-item",
})``;

export const StyledAccountNavLink = styled(NavLink).attrs({
  className: "nav-item",
})`
  font-family: ${({ theme }) => theme.skin === "pub" && "Montserrat"};
  ${(props) => {
    if (props.theme.skin === "rac") {
      return `
        cursor: pointer;
        text-transform: capitalize;
        padding-left: 0;
        color: var(--labelcolor);
        font-weight: 700;
        display: block;
        padding: 0.5rem 1rem;
        &.active {
          color: var(--mainmenu);
      }
      
      `;
    }
  }}
`;

export const StyledTerms = styled.div`
  ol,
  ul {
    margin-left: 20px;
    list-style-position: outside;
  }
  li {
    display: list-item !important;
    padding: 5px 10px;
  }
  ol:before {
    font-size: 15px;
  }
`;

export const DepositTransactionWrapper = styled.div`
  background: var(--profileHeaderBgABC);
`;

export const AttachmentImg = styled.div`
  width: 50vh;
  position: absolute;
  z-index: ${({ tournaments }) => (tournaments ? "4" : "1")};
  left: ${({ left, theme, lobby }) =>
    left && theme.skin === "yak" && lobby ? "-10vh" : left && "-30vh"};
  right: ${({ right }) => right && "-30vh"};
  top: ${({ left, lobby }) => left && (lobby ? "" : "100px")};
  bottom: ${({ right, lobby }) => right && (lobby ? "" : "100px")};
  opacity: ${({ lobby }) => (lobby ? "1" : "0.3")};

  .st0 {
    fill: ${({ theme }) => (theme.current === "light" ? "#f5fafd" : "#030d31")};
  }
  .st1 {
    fill: ${({ theme }) => (theme.current === "light" ? "#e4e7ec" : "#0A174B")};
  }
  .st2 {
    fill: ${({ theme }) => (theme.current === "light" ? "#e4e7ec" : "#0A174B")};
  }
  @media (max-width: 1024px) {
    width: 50vh;
  }
  svg {
    height: ${isMobileOnly ? "70vh" : "100%"};
    width: ${isMobileOnly && "100%"};
  }
`;
export const AboutImgWrapper = styled.div`
  text-align: center;
  padding: 1rem 0;

  img {
    height: 50px;
  }
`;

export const VerificationRACWrapper = styled.div.attrs({
  className: "border rounded mt-3 p-4",
})``;

export const TournamentsWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
`;

export const TournamentsHeader = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: ${isMobileOnly ? "wrap" : ""};
`;

export const TournamentsFlex = styled.div`
  width: ${isMobileOnly ? "100%" : "50%"};
  padding: ${isMobileOnly ? "1rem 0" : ""};
  color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};

  select {
    background-color: ${({ theme }) =>
      theme.skin === "fun" ? "var(--tournamentCardBg)" : "var(--inputbgABC)"};
    color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
    width: 100% !important;
    height: 100%;
    text-align-last: left;
    padding: 0.75rem 0.75rem;
    direction: ltr;
    :focus {
      background-color: ${({ theme }) =>
        theme.skin === "fun" ? "var(--tournamentCardBg)" : "var(--inputbgABC)"};
      color: ${({ theme }) =>
        theme.skin === "fun" ? "#fff" : "var(--acolor)"};
    }
    option {
      text-align: center;
      background-color: ${({ theme }) =>
        theme.skin === "fun" ? "var(--tournamentCardBg)" : "var(--inputbgABC)"};
      color: ${({ theme }) =>
        theme.skin === "fun" ? "#fff" : "var(--acolor)"};
    }
  }
  h4 {
    color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
    text-transform: capitalize;
    line-height: 1;
    font-weight: 400;
  }
  :first-child {
    text-align: left;
  }
  :last-child {
    height: ${isMobileOnly ? "" : "calc(2.25em + 1.4rem)"};
    display: flex;
    justify-content: ${isMobileOnly ? "center" : "end"};

    button {
      margin-left: 1rem;
      min-width: 100px;
      border-radius: ${({ theme }) => theme.skin === "fun" && "30px"};
    }

    div {
      width: ${isMobileOnly ? "70%" : "50%"};
    }
    text-align: right;
  }
`;

export const TournamentsBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: ${isMobileOnly ? "wrap" : ""};
`;
export const TournamentsBodyCard = styled.div`
  width: ${isMobileOnly ? "100%" : "32%"};
  margin: ${({ theme, secondStyle }) =>
    isMobileOnly &&
    ((theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc") &&
    !secondStyle
      ? "1rem 2rem"
      : "1rem 0")};
  height: ${({ extra }) => (extra ? "450px" : "125px")};
  border: ${({ theme, secondStyle, abc }) =>
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc"
      ? ""
      : theme.skin === "pub" && !secondStyle
      ? ""
      : abc
      ? "2px solid var(--tournamentsBorderABCpast)"
      : "2px solid var(--tournamentsBorder)"};
  border-radius: ${({ theme, secondStyle, leaderboardSecondStyle }) =>
    theme.skin === "fun" || secondStyle || leaderboardSecondStyle
      ? "30px"
      : theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc"
      ? ""
      : "9px"};
  border-bottom: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc") &&
    isMobileOnly &&
    "2px solid var(--tournamentsBorder)"};
  display: flex;
  /* align-items: center; */
  flex-wrap: ${({ extra }) => extra && "wrap"};
  background-color: ${({ previous, theme, leaderboardSecondStyle }) =>
    previous
      ? "var(--torunamentPastCardBg)"
      : theme.skin === "yet" || theme.skin === "fun"
      ? "var(--tournamentCardBg)"
      : leaderboardSecondStyle
      ? "var(--tournamentBodyInfo)"
      : ""};
  overflow: ${({ theme, secondStyle, leaderboardSecondStyle }) =>
    !leaderboardSecondStyle &&
    !secondStyle &&
    (theme.skin === "cac" || theme.skin === "pub")
      ? ""
      : "auto"};
  padding: ${({ previous, leaderboardSecondStyle }) =>
    (previous || leaderboardSecondStyle) && "1rem"};
  margin: ${({ leaderboardSecondStyle }) => leaderboardSecondStyle && "auto"};
  justify-content: center;
  -webkit-mask-image: linear-gradient(to bottom, #000 100%, transparent);
  mask-image: linear-gradient(to bottom, #000 100%, transparent);

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "cac" || theme.skin === "hyc"
        ? "#AAA2A5"
        : "var(--footerTextColor)"};
    border-radius: 5px;
    border: 0px solid var(--scrollbarBG);
  }
`;

export const TournamentsBodyInfo = styled.div`
  background-color: var(--tournamentBodyInfo);
  width: 100%;
  border-radius: ${({ theme }) => (theme.skin === "cac" ? "24px" : " ")};
  display: flex;
  justify-content: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc") &&
    "right"};
  flex-wrap: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc") &&
    isMobileOnly &&
    "wrap"};
`;

export const TournamentsBodyCardContent = styled.div`
  width: ${({ leftcol, rightcol }) =>
    leftcol ? "40%" : rightcol ? "60%" : "50%"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
  border-right: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc") &&
    !isMobile &&
    "2px solid #393939"};
  margin: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc") &&
    "2rem 0"};
  p {
    margin-bottom: 0;
    color: ${({ theme }) =>
      theme.skin === "fun"
        ? "#fff"
        : theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc"
        ? "var(--tournamentDescriptionColor)"
        : "var(--acolor)"};
  }
`;

export const TournamentsBodyCardDate = styled.div`
  text-align: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc") &&
    "left"};
  :first-child {
    width: 30%;
    font-size: 42px;
  }
  :last-child {
    width: ${({ theme }) =>
      theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc"
        ? "80%"
        : "50%"};
    font-size: 1rem;
    line-height: 1;
  }
`;

export const StyledAllPrizes = styled.div`
  width: 50%;
  font-size: ${({ theme }) => (theme.skin === "rac" ? "22px" : "25px")};
  font-weight: 600;
`;

export const TournamentsDot = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 0.5rem;
  background-color: ${({ red, green, gold, silver, bronze, gray, rest }) => {
    if (red) return `#ff5500`;
    if (green) return `#1fff00`;
    if (gold) return `#ffd64c`;
    if (silver) return `#8a8a8a`;
    if (bronze) return `#9b6e54`;
    if (gray) return `#44547a`;
    if (rest) return rest;
  }};
  border-radius: 50%;
  display: inline-block;
`;

export const StyledMoneyBag = styled(MoneyBag)`
  width: ${({ theme }) =>
    theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc"
      ? "50px"
      : "15px"};
  margin-right: 0.5rem;
  display: inline-block;
  .mon1 {
    fill: var(--tournamentMoneyBag);
  }
`;

export const StyledCalendarGreen = styled(CalendarGreen)`
  display: inline-block;
  width: 50px;
`;

export const StyledCalendarRed = styled(CalendarRed)`
  margin-right: 0.5rem;
  display: inline-block;
  width: 50px;
`;

export const PrizePoolWrapper = styled.div`
  width: 100%;
  height: 125px;
  display: flex;
  flex-wrap: nowrap;
  background-color: ${({ theme }) =>
    theme.skin === "fun" || theme.skin === "nbc"
      ? "var(--tournamentSelect)"
      : "var(--inputbgABC)"};
  border-radius: 9px 9px 0 0;
`;

export const PrizePoolWrapperSecondStyle = styled.div`
  width: 100%;
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
  flex-wrap: ${isMobileOnly ? "wrap" : ""};
`;

export const PrizePoolSecondStyle = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.skin === "cac" && "20px"};
  background-color: var(--inputbgABC);
  padding: 0.6rem;
  margin: ${isMobileOnly ? "5px" : ""};
  div {
    :nth-child(2) {
      padding-left: 10px;
    }
  }
  /* div {
  } */
`;

export const PrizePool = styled.div`
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  border-bottom: ${({ extraFont }) =>
    extraFont ? "" : "1px solid var(--tournamentsBorder)"};
  margin: auto;
  text-align: left;
  justify-content: space-between;
  height: 60px;
  font-size: ${({ extraFont }) => (extraFont ? "1.3rem" : "14px")};
  font-weight: ${({ extraFont }) => extraFont && "600"};
  text-transform: ${({ extraFont }) =>
    extraFont ? "uppercase" : "capitalize"};
  color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
  align-content: center;

  :first-child {
    height: 100px;
  }
  :last-child {
    border: none;
  }
  h4 {
    color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
    text-transform: capitalize;
    line-height: 1;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const TournamentGameDescription = styled.div`
  width: ${({ secondStyle }) => (isMobile || secondStyle ? "100%" : "64%")};
  margin: ${({ theme }) =>
    theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc"
      ? "5rem 0"
      : "1rem 0"};
  border-bottom: 1px solid var(--tournamentsBorder);
  text-align: left;
  position: ${({ theme }) =>
    isMobile ||
    theme.skin === "cac" ||
    theme.skin === "pub" ||
    theme.skin === "hyc"
      ? ""
      : "relative"};
  top: -200px;
  color: var(--tournamentDescriptionColor);
  h2 {
    color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
    font-size: 20px;
  }
  div {
    min-height: 120px;
    padding-bottom: 4rem;
  }
`;
export const TournamentRules = styled.div`
  width: ${({ secondStyle }) => (isMobile || secondStyle ? "100%" : "100%")};
  margin: 1rem 0;
  text-align: left;
  color: var(--tournamentDescriptionColor);
  position: ${({ secondStyle }) => (isMobile || secondStyle ? "" : "relative")};
  top: -150px;
  display: flex;
  flex-wrap: ${isMobileOnly && "wrap"};
  flex-direction: ${isMobileOnly && "column-reverse"};
  h2 {
    color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
    font-size: 20px;
  }
  p {
    color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
  }
`;
export const TournamentRulesDiv = styled.div`
  width: ${({ secondStyle }) => (isMobile || secondStyle ? "100%" : "68%")};
  p {
    color: ${({ theme }) => (theme.skin === "fun" ? "#fff" : "var(--acolor)")};
  }
`;

export const PreviousTournamentsWrapper = styled.div`
  width: ${({ theme }) =>
    theme.skin === "cac" || theme.skin === "hyc" ? "90%" : "100%"};
  margin: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "auto"};
  border-radius: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "35px"};
  min-height: 700px;
  background-color: var(--tournamentPastBg);
  -webkit-mask-image: linear-gradient(to bottom, #000 100%, transparent);
  mask-image: linear-gradient(to bottom, #000 100%, transparent);
`;

export const PreviousTournamentsHeader = styled.h3.attrs({
  className: "container",
})`
  color: ${({ theme }) =>
    theme.skin === "fun"
      ? "#fff"
      : theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc"
      ? "var(--tournamentDescriptionColor)"
      : "var(--acolor)"};
  text-align: ${({ theme }) =>
    theme.skin === "cac" || theme.skin === "pub" || theme.skin === "hyc"
      ? "center"
      : "left"};
  padding-top: 4rem;
`;

export const PreviousTournamentsCardWapper = styled.div.attrs({
  className: "container",
})`
  display: flex;
  color: #fff;
  justify-content: space-between;
  font-size: 16px;
  margin: 4rem auto;
  flex-wrap: wrap;
`;

export const RacDivSeparator = styled.div`
  background-color: transparent;
  height: 150px;
  z-index: 1;
`;

export const RacMarketingNotifications = styled.div`
  display: flex;
  padding-top: 1rem;
  width: ${isMobileOnly ? "100%" : "15%"};
  padding-bottom: 2rem;
`;

export const BoxingWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 3rem 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const SwitchBonusesWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  padding: 1rem 0;
  border-bottom: 1px solid var(--tournamentsBorder);
`;

export const BonusButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--acolor);
  font-size: 1rem;
  text-decoration: ${({ active }) =>
    active && "underline 3px rgb(253, 175, 30)"};
  text-underline-offset: 8px;
  cursor: pointer;
`;
