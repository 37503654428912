import styled from "styled-components";
import { ReactComponent as PlayLiveTop } from "../../../plc/src/data/icon-component/border_top.svg";
import { ReactComponent as PlayLiveBottom } from "../../../plc/src/data/icon-component/border_bottom.svg";
import { ReactComponent as CloverBig } from "../../../plc/src/data/icon-component/clover-big.svg";
import { ReactComponent as CloverSmall } from "../../../plc/src/data/icon-component/clover-small.svg";
import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";
import PlayLivePUB from "../../../pub/src/data/img/play-live-bg.webp";
import PlayLivePUBsafari from "../../../pub/src/data/img/play-live-bg.png";
import PlayLiveCAC from "../../../cac/src/data/img/playlive.png";
import PlayLiveHYC from "../../../hyc/src/data/img/playlive.png";
import PlayLiveHYCBackground from "../../../hyc/src/data/img/bg_desktop.jpg";

export const StyledFiltersWrapper = styled.div`
  z-index: 2000;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) =>
    theme.skin === "abc"
      ? ""
      : isMobile
      ? "10px"
      : theme.skin === "plc" ||
        theme.skin === "yet" ||
        theme.skin === "nbc" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
      ? ""
      : "20px"};
  background-color: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "bnk" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "var(--mainDescriptionABC)"
      : ""};
  background-color: & > input {
    visibility: hidden;
  }
  padding: ${({ theme }) =>
    (theme.skin === "fun" || theme.skin === "yak") &&
    isMobileOnly &&
    "10px 20px"};
  padding-bottom: ${({ theme }) => theme.skin === "bnk" && "20px"};
`;

export const FilterABCWrapper = styled.div`
  width: ${isDesktop ? "100%" : "100%"};
  max-width: ${isDesktop ? "1600px" : "100%"};
  margin: 0 auto;
  background: rgb(0, 14, 43);
  background: var(--categoriesBgLinearABC);
  padding: ${({ theme }) =>
    theme.skin === "fun" || theme.skin === "yak"
      ? "15px 10px 15px 10px"
      : "30px 0 0 0"};
  background-size: 110%;
  border-radius: ${({ theme }) =>
    (theme.skin === "fun" || theme.skin === "yak") && "20px"};

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
`;

export const StyledFilters = styled.div`
  height: auto;
  width: 1600px;
  max-width: 100%;
  padding: ${({ theme }) =>
    isMobile ? (theme.skin === "rac" ? "" : "0 10px") : "0 40px"};
  display: flex;
  flex-direction: row;
  justify-content: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "space-between"
      : theme.skin === "qbc"
      ? "right"
      : "center"};
  align-items: center;

  & > a {
    display: block;
  }
  /* background-color: red; */
  button {
    font-family: ${({ theme }) => theme.skin === "fun" && "MorningBreeze"};
  }

  @media (max-width: 770px) {
    /* display: none; */
  }
`;

export const StyledLoupeDiv = styled.div`
  width: ${({ mobileNav }) => mobileNav && "20%"};
  cursor: pointer;
  color: ${({ theme }) => (theme.skin === "abc" ? "#fff" : "var(--icon)")};

  &:after {
    margin: 0 auto;
    font-family: "Material Icons";
    content: "search";
    font-size: ${({ mobileNav }) => (mobileNav ? "1.7rem" : "1.5rem")};
    font-weight: 500;
    color: ${({ active, theme, mobileNav }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak" ||
        theme.skin === "rac") &&
      mobileNav
        ? active
          ? "var(--navIconColor)"
          : theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak"
          ? "#fff"
          : theme.skin === "rac"
          ? "var(--icon)"
          : "var(--brandcolor)"
        : active && "#fff"};
    background-color: ${({ theme, mobileNav }) =>
      (theme.skin === "fun" || theme.skin === "yak") &&
      !mobileNav &&
      "var(--lobbybg)"};
    border-radius: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "50%"};
    padding: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "5px"};
  }
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const StyledFader = styled.section`
  /* transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease; */
  padding: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? ""
      : theme.skin === "rac"
      ? "0px"
      : "100px 0"};
  //background-color: var(--footerbg);
  width: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? ""
      : "100%"};

  max-width: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "1600px"
      : theme.skin === "rac"
      ? "1200px"
      : "100%"};
  height: ${({ theme }) => (theme.skin !== "plc" ? "" : "500px")};
  border-bottom: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "1px solid var(--borderABC);"
      : ""};
  margin: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "0 auto"
      : theme.skin === "rac"
      ? "auto"
      : ""};
  background-color: ${({ theme }) =>
    theme.skin === "rac" && "var(--footerColor)"};
  /* transition: all 0.2s ease; */

  ${(props) => {
    if (props.theme.skin === "pub" || props.theme.skin === "cac") {
      return `
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: end;
        min-height: 50vh;
      `;
    }
  }}

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: ${({ theme }) =>
      (theme.skin === "pub" || theme.skin === "cac") && "0"};
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    height: ${({ theme }) =>
      (theme.skin === "pub" || theme.skin === "cac") && "100%"};
  }
`;

export const StyledPlayLiveWrapper = styled.section`
  width: ${({ theme }) => (theme.skin === "cac" ? "90%" : "100%")};
  max-width: ${({ theme }) => theme.skin === "cac" && "1600px"};
  margin: ${({ theme }) => (theme.skin === "cac" ? "0 auto" : "0")};
  padding: 0;
  font-family: inherit;

  @media only screen and (max-width: 767px) {
    .play-live-wrapper {
      height: 100%;
    }
  }
`;

export const StyledPlayLiveTop = styled(PlayLiveTop)`
  fill: var(--playLive);
  width: 100%;
`;

export const StyledPlayLiveBottom = styled(PlayLiveBottom)`
  fill: var(--playLive);
  width: 100%;
`;

export const PlayLiveWrapper = styled.div`
  height: ${({ theme }) => (theme.skin === "pub" ? "100vh" : "600px")};
  background-color: var(--playLive);
  position: ${({ theme }) =>
    theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
      ? "relative"
      : ""};
  margin-top: -10px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  border-radius: ${({ theme }) => (theme.skin === "cac" ? "30px" : "")};
  background: ${({ theme }) =>
    theme.skin === "hyc" && `url(${PlayLiveHYCBackground})`};
  background-repeat: no-repeat;
  background-size: 100vw 600px;

  .btn-first {
    margin: ${({ theme }) =>
      theme.skin === "pub"
        ? "20px"
        : theme.skin === "cac" || theme.skin === "hyc"
        ? "20px 0"
        : "70px 0px 20px 0px"};
    padding: ${({ theme }) =>
      theme.skin === "cac" ? "15px 20px" : "20px 0 !important"};
    min-width: 170px;
    display: inline;
    float: ${({ theme }) => (theme.skin === "pub" ? "none" : "left")};
    font-size: ${({ theme }) => (theme.skin === "pub" ? "0.93rem" : "1rem")};
  }
  @media only screen and (max-width: 767px) {
    height: ${({ theme }) =>
      theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
        ? ""
        : "101%"};
    padding-top: 20px;

    .btn-first {
      margin-top: 20px;
      margin-left: ${({ theme }) =>
        theme.skin === "cac" || theme.skin === "hyc" ? "40px" : "15px"};
      padding: 10px 0 !important;
    }
  }
  @media (min-width: 768px) and (max-width: 924px) {
    height: ${({ theme }) =>
      theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
        ? ""
        : "100%"};
  }
  @media (min-width: 924px) and (max-width: 1200px) {
    height: ${({ theme }) =>
      theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
        ? ""
        : "100%"};
  }
`;

export const PlayLivePUBImg = styled.div`
  background: url(${PlayLivePUB});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
`;

export const PlayLivePUBImgSafari = styled(PlayLivePUBImg)`
  background: url(${PlayLivePUBsafari});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
`;

export const PlayLiveCACImg = styled.div`
  background: url(${PlayLiveCAC});
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-size: ${isMobile ? "contain" : "450px"};
`;

export const PlayLiveHYCImg = styled.div`
  background: url(${PlayLiveHYC});
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-size: ${isMobile ? "contain" : "450px"};
`;

export const PlayLiveLeftWrapper = styled.div.attrs({
  className: "d-inline-flex p-1 bd-highlight",
})`
  width: 60%;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 924px) {
    width: 50%;
  }
  @media (min-width: 924px) and (max-width: 1200px) {
    width: 50%;
    height: 100%;
    margin: auto;
  }
`;

export const PlayLiveImgLeft = styled.img`
  clip-path: url(#myClip);
  height: 100% !important;
  margin: 0 auto;
  /* object-fit: cover; */
  align-self: flex-start;
  img {
    height: 100% !important;
    margin: 0 auto;
    /* object-fit: cover; */
  }

  @media only screen and (max-width: 767px) {
    z-index: 1;
    max-width: 100%;
    height: auto !important;
  }
  @media (min-width: 768px) and (max-width: 924px) {
    margin: auto;
    max-width: 100%;
    height: auto !important;
  }
  @media (min-width: 924px) and (max-width: 1200px) {
    width: 100%;
    margin: auto;
  }
`;

export const PlayLiveRightWrapper = styled.div.attrs({
  className: "d-flex p-2 bd-highlight",
})`
  width: ${({ theme }) => (theme.skin === "pub" ? "100%" : "40%")};
  margin: ${({ theme }) =>
    theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
      ? "auto"
      : ""};
  flex-wrap: wrap;
  z-index: 2;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 924px) {
    width: 50%;
  }
  @media (min-width: 924px) and (max-width: 1200px) {
    width: 50%;
  }
`;

export const StyledClowerSmall = styled(CloverSmall)`
  width: 150px;
  fill: var(--bgcolor);
  margin-left: 200px;

  @media only screen and (max-width: 767px) {
    position: relative;
    top: -300px;
  }
`;

export const StyledClowerBig = styled(CloverBig)`
  width: 150px;
  fill: var(--bgcolor);
  margin-left: 300px;

  @media only screen and (max-width: 767px) {
    position: relative;
    top: -100px;
  }
`;

export const PlayLiveExtend = styled.div`
  width: 100%;
  padding: 20px 0;

  @media only screen and (max-width: 767px) {
    margin-top: -80px;
  }
`;

export const PlayLiveH2 = styled.h2`
  font-family: ${({ theme }) => theme.skin === "pub" && "Ancho"};
  width: ${({ theme }) =>
    theme.skin === "pub" || theme.skin === "cac"
      ? isDesktop
        ? "500px"
        : ""
      : "350px"};
  font-size: ${({ theme }) => (theme.skin === "pub" ? "2.67rem" : "2.4rem")};
  font-weight: ${({ theme }) => (theme.skin === "pub" ? "400" : "1000")};
  color: var(--newsText);
  line-height: 1.3;
  text-align: ${({ theme }) => (theme.skin === "pub" ? "center" : "left")};
  padding: 1rem 0;
  margin: ${({ theme }) => (theme.skin === "pub" ? "auto" : "")};

  @media only screen and (max-width: 767px) {
    padding: ${({ theme }) => (theme.skin === "pub" ? "10px" : "0 0 0 30px")};
    margin: ${({ theme }) => (theme.skin === "pub" ? "0" : "10px 10px")};
    font-size: 2rem;
    width: ${({ theme }) => (theme.skin === "pub" ? "100%" : "90%")};
    text-align: ${({ theme }) => (theme.skin === "pub" ? "center" : "left")};
  }
`;

export const PlayLiveDescription = styled.div`
  font-weight: ${({ theme }) => (theme.skin === "pub" ? "500" : "800")};
  width: 300px;
  text-align: ${({ theme }) => (theme.skin === "pub" ? "center" : "left")};
  margin: ${({ theme }) => (theme.skin === "pub" ? "auto" : "")};
  padding: ${({ theme }) => (theme.skin === "pub" ? "1rem 0" : "")};
  color: ${({ theme }) =>
    theme.skin === "pub" ? "#5A5552" : "var(--newsText)"};

  @media only screen and (max-width: 767px) {
    padding: ${({ theme }) =>
      theme.skin === "pub" ? "1rem 0" : " 0 0 0 40px"};
    text-align: ${({ theme }) => (theme.skin === "pub" ? "center" : "left")};
    width: 75%;
    font-size: ${({ theme }) => (theme.skin === "pub" ? "0.93rem" : "0.8rem")};
  }
`;

export const StyledLandingSection = styled.section`
  margin-top: ${({ theme }) =>
    theme.skin === "pub" ? "160px" : isMobileOnly ? "110px" : "100px"};
`;

export const StyledLandingWrapper = styled.div.attrs((props) => ({
  className: props.registerform ? "col-lg-6 p-0" : "col-12",
}))`
  ${(props) => {
    if (props.register) {
      return `
      display: flex;
      `;
    }
  }}
`;

export const StyledLendingImg = styled.img.attrs({
  className: "img-fluid w-100 ",
})``;

export const LoaderRow = styled.div.attrs({
  className: "row min-vh-100 justify-content-center align-items-center",
})``;

export const LoaderCol = styled.div.attrs({
  className: "col-lg-12 text-center",
})``;

export const RotateLogo = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 auto;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const PulseAnimation = styled.div`
  width: 75px;
  height: ${({ bnk, noHeight }) => (bnk ? "20px" : noHeight ? "" : "35px")};
  margin: 0 auto;
  animation-name: pulse-animation;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes pulse-animation {
    0% {
      transform: scale(0.9);
      /* box-shadow: 0 0 0 0 rgba(82, 81, 81, 0.5); */
    }

    70% {
      transform: scale(1.3);
      /* box-shadow: 0 0 0 10px rgba(82, 81, 81, 0); */
    }

    100% {
      transform: scale(0.9);
      /* box-shadow: 0 0 0 0 rgba(82, 81, 81, 0); */
    }
  }
`;

export const RotateAnimation = styled.div`
  width: 75px;
  height: ${({ bnk, noHeight }) => (bnk ? "20px" : noHeight ? "" : "35px")};
  margin: 0 auto;
  animation-name: rotation;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
