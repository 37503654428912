import React, { useEffect, useState, useReducer } from "react";
import { Redirect } from "react-router";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { TabContent } from "reactstrap";
import { ContentTop } from "common/src/component/ContentTop";
import {
  AccountContainer,
  InvalidFeedback,
  PageContent,
  PageContentWrapper,
  StyledCol,
  TransactionDescription,
} from "common/src/styledComponents/AccountPage";

import {
  ResetFormGroup,
  ResetWrapper,
} from "common/src/styledComponents/StyledSignupLogin";
import {
  StyledAlert,
  StyledCustomCheckboxWrapper,
} from "common/src/styledComponents/StyledSignupLogin";
import { QUERY_HOOYU_REQUEST_LINK } from "common/src/graphql/queries";
import { toast } from "react-toastify";
import HooyuIFrame from "common/src/component/HooyuIFrame.js";
import { useApolloClient } from "@apollo/client";
import { StyledSectionTimeLimit } from "common/src/styledComponents/StyledLimits";
import {
  StyledButton,
  StyledCustomControlLabel,
  StyledInput,
  StyledSelect,
} from "common/src/styledComponents/CommonStyledComponents";
import { MUTATION_AFFORDABILLITY_CHECK } from "common/src/graphql/mutations";
import {
  // AFFORDABILITY_DIALOG,
  AFFORDABILITY_FORM,
  HOOYU_MULTIPLE,
} from "common/src/helpers/constants";
import EddRgComponent from "common/src/component/EddRgComponent";

const Verification = ({
  player,
  pages,
  titles,
  serverSetup,
  skin,
  setSpinner,
  setAffordabilityPopupLimit,
  setAffordabilityPopup,
  setShowFinishAffAndEdd,
}) => {
  const apolloClient = useApolloClient();
  const { t, i18n } = useTranslation("");
  const [kycIframeSrc, setkycIframeSrc] = useState("");
  //affordability fields
  const [budget, setBudget] = useState("");
  const [occupation, setOccupation] = useState("");
  const [sourceOfFunds, setSourceOfFunds] = useState([]);
  const [sourceOfFundsOther, setSourceOfFundsOther] = useState("");
  const [sourceOfFundsOtherChecked, setSourceOfFundsOtherChecked] =
    useState(false);
  const [sourceOfWealth, setSourceOfWealth] = useState([]);
  const [sourceOfWealthOther, setSourceOfWealthOther] = useState("");
  const [sourceOfWealthOtherChecked, setSourceOfWealthOtherChecked] =
    useState(false);
  const [homepageRedirect, setHomepageRedirect] = useState(false);
  const [estimatedDisposableAnnualIncome, setEstimatedDisposableAnnualIncome] =
    useState("");
  const [eddConfirmCheckbox, setEddConfirmCheckbox] = useState(false);

  const [error, setError] = useState({});
  const { country, currency } = player;
  // const { currencies } = serverSetup;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showAffordability, setShowAffordability] = useState(false);

  let plcConfig = skin === "plc" ? true : false;
  const isSESEK = country === "se" && currency === "sek" ? true : false;
  // console.log(sourceOfFundsOtherChecked ? sourceOfFunds.join(", ") + (sourceOfFundsOther ? (sourceOfFunds.length ? ", " + sourceOfFundsOther : sourceOfFundsOther) : "") : sourceOfFunds.join(", "))
  // console.log("SOW: " + (sourceOfWealthOtherChecked ? sourceOfWealth.join(", ") + ", " + sourceOfWealthOther : sourceOfWealth.join(", ")))
  // console.log(error)

  useEffect(() => {
    // console.log(titles)
    document.title = titles["my-verification"];
  }, [titles]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
    if (localStorage.getItem(AFFORDABILITY_FORM)) {
      setShowAffordability(true);
      setShowFinishAffAndEdd(true);
    }
    /* return () => {
      localStorage.removeItem(AFFORDABILITY_FORM);
    }; */
  }, [setShowFinishAffAndEdd]);

  useEffect(() => {
    async function startKYC() {
      if (skin === "plc") {
        return true;
      }
      // console.log("not plc");
      setSpinner(true);
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_HOOYU_REQUEST_LINK,
      });
      if (loading) return <h3>Loading ...</h3>;
      if (errors) {
        toast.error(t(errors[0].message), {
          position: "bottom-center",
        });
        setSpinner(false);
        return false;
      }
      setSpinner(false);
      setkycIframeSrc(data.getHooyuRequestLink.link);
      if (data.getHooyuRequestLink.journeyCount > 1) {
        localStorage.setItem(HOOYU_MULTIPLE, data.getHooyuRequestLink.link);
      }
      return true;
    }

    if (
      localStorage.getItem(AFFORDABILITY_FORM) === null &&
      !showAffordability
    ) {
      // console.log("start kyc");
      startKYC();
    }
  }, [player, apolloClient, setSpinner, t, skin, showAffordability]);

  async function handleSubmit() {
    handleErrorCheck();
    if (
      occupation.length > 2 &&
      (sourceOfFunds.length > 0 || sourceOfFundsOther.trim().length > 2) &&
      estimatedDisposableAnnualIncome.length > 0 &&
      eddConfirmCheckbox &&
      budget.length > 0
    ) {
      let params = {
        occupation: occupation,
        sourceOfWealth: sourceOfWealthOtherChecked
          ? sourceOfWealth.join("; ") +
            (sourceOfWealthOther
              ? sourceOfWealth.length
                ? "; " + sourceOfWealthOther
                : sourceOfWealthOther
              : "")
          : sourceOfWealth.join("; "),
        sourceOfFunds:
          sourceOfFunds.length === 0 && sourceOfFundsOther === ""
            ? null
            : sourceOfFundsOtherChecked
            ? sourceOfFunds.join("; ") +
              (sourceOfFundsOther
                ? sourceOfFunds.length
                  ? "; " + sourceOfFundsOther
                  : sourceOfFundsOther
                : "")
            : sourceOfFunds.join("; "),
        annualIncome: estimatedDisposableAnnualIncome,
        gamblingBudget: budget,
      };
      // console.log(params);
      // return;
      setSpinner(true);
      const { loading, errors, data } = await apolloClient.mutate({
        mutation: MUTATION_AFFORDABILLITY_CHECK,
        variables: params,
      });

      setSpinner(false);
      if (loading) return "Loading ...";
      if (errors) {
        //console.log(errors);
        //setLoginError(errors);
        toast.error(t(errors[0].message), {
          position: "bottom-center",
        });
        return false;
      } else
        toast.success(t("update_success"), {
          position: "bottom-center",
        });
      // console.log(data.affordabilityCheck)
      // console.log(data.affordabilityCheck.limitAmount);
      // sessionStorage.setItem(AFFORDABILITY_DIALOG, data.affordabilityCheck.popupVersion);
      setAffordabilityPopup(data.affordabilityCheck.popupVersion);
      setAffordabilityPopupLimit(data.affordabilityCheck.limitAmount);
      setHomepageRedirect(true);

      // window.location.replace(pages["index"]);
      localStorage.removeItem(AFFORDABILITY_FORM);
      return true;
    }
  }

  const handleErrorCheck = () => {
    if (occupation.length < 4) {
      error.occupation = "err_missing_occupation";
    } else {
      delete error.occupation;
    }
    if (sourceOfFunds.length === 0 && sourceOfFundsOther.trim() === "") {
      error.source_of_funds = "err_missing_source_of_funds";
    } else {
      delete error.source_of_funds;
    }
    // if (sourceOfFunds.length === 0 && sourceOfFundsOther === "") {
    //   error.source_of_funds_other = "err_missing_source_of_funds";
    // } else {
    //   delete error.source_of_funds_other;
    // }
    /* if (sourceOfWealth === "") {
      error.source_of_wealth = "err_missing_source_of_wealth";
    } else {
      delete error.source_of_wealth;
    }
    if (sourceOfWealth === "Other" && sourceOfWealthOther === "") {
      error.source_of_wealth_other = "err_missing_source_of_wealth";
    } else {
      delete error.source_of_wealth_other;
    } */
    if (estimatedDisposableAnnualIncome === "") {
      error.estimated_disposable_annual_income =
        "err_missing_estimated_disposable_income";
    } else {
      delete error.estimated_disposable_annual_income;
    }
    if (budget === "") {
      error.budget = "err_empty_gambling_budget";
    } else {
      delete error.budget;
    }
    setError(error);
    forceUpdate();
  };

  const handleSourceOfFundsChange = (value, checked) => {
    // console.log(value + ": " + checked);
    if (checked) {
      if (sourceOfFunds.indexOf(value) < 0) {
        setSourceOfFunds((current) => [...current, value]);
      }
    } else {
      if (sourceOfFunds.indexOf(value) >= 0) {
        setSourceOfFunds((current) => current.filter((sof) => sof !== value));
      }
    }
  };

  const handleSourceOfWealthChange = (value, checked) => {
    // console.log(value + ": " + checked);
    if (checked) {
      if (sourceOfWealth.indexOf(value) < 0) {
        setSourceOfWealth((current) => [...current, value]);
      }
    } else {
      if (sourceOfWealth.indexOf(value) >= 0) {
        setSourceOfWealth((current) => current.filter((sof) => sof !== value));
      }
    }
  };

  if (homepageRedirect) {
    return <Redirect to={pages["index"]}></Redirect>;
  }

  return (
    <>
      <ContentTop
        serverSetup={serverSetup}
        showProfileMenu={!isMobileOnly}
        pages={pages}
        skin={skin}
        isMyAccount={"label_verification"}
      />
      <PageContent account="true" nobg="true">
        <PageContentWrapper>
          <AccountContainer history>
            <StyledCol>
              <TabContent rac="true">
                {player && player.isDataLinkActive && (
                  <EddRgComponent
                    player={player}
                    setSpinner={setSpinner}
                    dataLink={''}
                    skin={skin}
                    useDialog={false}
                  ></EddRgComponent>
                )}
                <TransactionDescription
                  dangerouslySetInnerHTML={{
                    __html: t("label_verification_description"),
                  }}
                >
                  {/* <div>
                    <h3>How does All British Casino verify my account?</h3>{" "}
                    <p>
                      When verification is required we will send an email to
                      inform you. The KYC (Know your customer) verification
                      process is an automated process carried out by our partner
                      Hooyu. When we have initiated the verification process you
                      will see a window enabled on this page, that will allow
                      you to start and complete the verification process. Bear
                      in mind that all transfer of documentation is safe and
                      secured.&nbsp;&nbsp;
                    </p>{" "}
                    <p>The verification status of your account is:</p>
                  </div> */}
                </TransactionDescription>
                <StyledAlert
                  alert={player.isVerified}
                  role="alert"
                  id="scroll-to-aff-from"
                >
                  {player.isVerified
                    ? t("label_verified")
                    : t("label_not_verified")}
                </StyledAlert>
                {kycIframeSrc !== "" && !showAffordability && (
                  <HooyuIFrame
                    src={kycIframeSrc}
                    className=""
                    width="100%"
                    height="1000px"
                    pages={pages}
                  />
                )}
              </TabContent>
            </StyledCol>
            {showAffordability && (
              <div className={"mt-5"}>
                <TransactionDescription
                  affedd
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "label_affordability_form_description",
                      "label_affordability_form_description"
                    ),
                  }}
                ></TransactionDescription>{" "}
                <ResetWrapper limit timelimit timelimitAbc verification>
                  <ResetFormGroup mt>
                    <label>{t("label_occupation", "label_occupation")}</label>

                    <StyledInput
                      cp
                      type="text"
                      error={error.occupation}
                      onChange={(e) => setOccupation(e.target.value)}
                      /* onBlur={handleErrorCheck} */
                    />
                    {error.occupation && (
                      <InvalidFeedback>{t(error.occupation)}</InvalidFeedback>
                    )}
                  </ResetFormGroup>
                  {/*<ResetFormGroup>*/}
                  {/*  <label>{t("label_source_of_funds")}</label>*/}
                  {/*  <StyledSelect*/}
                  {/*    selecCenter*/}
                  {/*    error={error.source_of_funds}*/}
                  {/*    onChange={(e) => setSourceOfFunds(e.target.value)}*/}
                  {/* onBlur={handleErrorCheck} */}
                  {/*    defaultValue={"DEFAULT"}*/}
                  {/*  >*/}
                  {/*    <option value="DEFAULT" disabled></option>*/}
                  {/*    <option value={"Family fortune or inheritance"}>*/}
                  {/*      {t("label_family_fortune")}*/}
                  {/*    </option>*/}
                  {/*    <option value={"Employment"}>*/}
                  {/*      {t("label_employment")}*/}
                  {/*    </option>*/}
                  {/*    <option value={"Own business"}>*/}
                  {/*      {t("label_own_business")}*/}
                  {/*    </option>*/}
                  {/*    <option value={"Investment"}>*/}
                  {/*      {t("label_investment")}*/}
                  {/*    </option>*/}
                  {/*    <option value={"Savings"}>{t("label_savings")}</option>*/}
                  {/*    <option value={"Other"}>{t("label_other")}</option>*/}
                  {/*  </StyledSelect>*/}
                  {/*  {error.source_of_funds && (*/}
                  {/*    <InvalidFeedback>*/}
                  {/*      {t(error.source_of_funds)}*/}
                  {/*    </InvalidFeedback>*/}
                  {/*  )}*/}
                  {/*</ResetFormGroup>*/}
                  {/*<ResetFormGroup*/}
                  {/*    className={sourceOfFunds === "Other" ? "" : "hidden"}*/}
                  {/*>*/}
                  {/*  <label>{t("label_source_of_funds_other")}</label>*/}
                  {/*  <StyledInput*/}
                  {/*      cp*/}
                  {/*      type="text"*/}
                  {/*      error={error.source_of_funds_other}*/}
                  {/*      onChange={(e) => setSourceOfFundsOther(e.target.value)}*/}
                  {/*      onBlur={handleErrorCheck} */}
                  {/*  />*/}
                  {/*  {error.source_of_funds_other && (*/}
                  {/*      <InvalidFeedback>*/}
                  {/*        {t(error.source_of_funds_other)}*/}
                  {/*      </InvalidFeedback>*/}
                  {/*  )}*/}
                  {/*</ResetFormGroup>*/}
                  {/*<ResetFormGroup>*/}
                  {/*  <label>{t("label_source_of_wealth")}</label>*/}
                  {/*  <StyledSelect*/}
                  {/*    selecCenter*/}
                  {/*    error={error.source_of_wealth}*/}
                  {/*    onChange={(e) => setSourceOfWealth(e.target.value)}*/}
                  {/*    onBlur={handleErrorCheck} */}
                  {/*    defaultValue={"DEFAULT"}*/}
                  {/*  >*/}
                  {/*    <option value="DEFAULT" disabled></option>*/}
                  {/*    <option value={"Family fortune or inheritance"}>*/}
                  {/*      {t("label_family_fortune")}*/}
                  {/*    </option>*/}
                  {/*    <option value={"Employment"}>*/}
                  {/*      {t("label_employment")}*/}
                  {/*    </option>*/}
                  {/*    <option value={"Own business"}>*/}
                  {/*      {t("label_own_business")}*/}
                  {/*    </option>*/}
                  {/*    <option value={"Investment"}>*/}
                  {/*      {t("label_investment")}*/}
                  {/*    </option>*/}
                  {/*    <option value={"Savings"}>{t("label_savings")}</option>*/}
                  {/*    <option value={"Other"}>{t("label_other")}</option>*/}
                  {/*  </StyledSelect>*/}
                  {/*  {error.source_of_wealth && (*/}
                  {/*    <InvalidFeedback>*/}
                  {/*      {t(error.source_of_wealth)}*/}
                  {/*    </InvalidFeedback>*/}
                  {/*  )}*/}
                  {/*</ResetFormGroup>*/}
                  {/*<ResetFormGroup*/}
                  {/*  className={sourceOfWealth === "Other" ? "" : "hidden"}*/}
                  {/*>*/}
                  {/*  <label>{t("label_source_of_wealth_other")}</label>*/}
                  {/*  <StyledInput*/}
                  {/*    cp*/}
                  {/*    type="text"*/}
                  {/*    error={error.source_of_wealth_other}*/}
                  {/*    onChange={(e) => setSourceOfWealthOther(e.target.value)}*/}
                  {/*    onBlur={handleErrorCheck}*/}
                  {/*  />*/}
                  {/*  {error.source_of_wealth_other && (*/}
                  {/*    <InvalidFeedback>*/}
                  {/*      {t(error.source_of_wealth_other)}*/}
                  {/*    </InvalidFeedback>*/}
                  {/*  )}*/}
                  {/*</ResetFormGroup>*/}
                  <ResetFormGroup className="mt-5">
                    <label>{t("label_sources_of_funds")}</label>
                    <StyledCustomCheckboxWrapper
                      affordability="true"
                      className="mt-3"
                    >
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Family fortune or inheritance"
                        error={error.source_of_funds}
                        onChange={(e) =>
                          handleSourceOfFundsChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sof_family_fortune"
                      />
                      <StyledCustomControlLabel htmlFor="sof_family_fortune">
                        {t("label_family_fortune")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>

                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Employment"
                        error={error.source_of_funds}
                        onChange={(e) =>
                          handleSourceOfFundsChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sof_employment"
                      />
                      <StyledCustomControlLabel htmlFor="sof_employment">
                        {t("label_employment")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Own business"
                        error={error.source_of_funds}
                        onChange={(e) =>
                          handleSourceOfFundsChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sof_own_business"
                      />
                      <StyledCustomControlLabel htmlFor="sof_own_business">
                        {t("label_own_business")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Investment"
                        error={error.source_of_funds}
                        onChange={(e) =>
                          handleSourceOfFundsChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sof_investment"
                      />
                      <StyledCustomControlLabel htmlFor="sof_investment">
                        {t("label_investment")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Savings"
                        error={error.source_of_funds}
                        onChange={(e) =>
                          handleSourceOfFundsChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sof_savings"
                      />
                      <StyledCustomControlLabel htmlFor="sof_savings">
                        {t("label_savings")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Other"
                        error={error.source_of_funds}
                        onChange={(e) =>
                          setSourceOfFundsOtherChecked(e.target.checked)
                        }
                        id="sof_other"
                      />
                      <StyledCustomControlLabel htmlFor="sof_other">
                        {t("label_other")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    {error.source_of_funds && (
                      <InvalidFeedback>
                        {t(error.source_of_funds)}
                      </InvalidFeedback>
                    )}
                  </ResetFormGroup>
                  <ResetFormGroup
                    className={sourceOfFundsOtherChecked ? "" : "hidden"}
                  >
                    {/*<label>{t("label_source_of_funds_other")}</label>*/}
                    <StyledInput
                      cp
                      type="text"
                      error={error.source_of_funds_other}
                      onChange={(e) => setSourceOfFundsOther(e.target.value)}
                      /* onBlur={handleErrorCheck} */
                    />
                    {error.source_of_funds_other && (
                      <InvalidFeedback>
                        {t(error.source_of_funds_other)}
                      </InvalidFeedback>
                    )}
                  </ResetFormGroup>
                  <ResetFormGroup className="mt-5">
                    <label>{t("label_sources_of_wealth")}</label>
                    <StyledCustomCheckboxWrapper
                      affordability="true"
                      className="mt-3"
                    >
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Family fortune or inheritance"
                        error={error.source_of_wealth}
                        onChange={(e) =>
                          handleSourceOfWealthChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sow_family_fortune"
                      />
                      <StyledCustomControlLabel htmlFor="sow_family_fortune">
                        {t("label_family_fortune")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>

                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Employment"
                        error={error.source_of_wealth}
                        onChange={(e) =>
                          handleSourceOfWealthChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sow_employment"
                      />
                      <StyledCustomControlLabel htmlFor="sow_employment">
                        {t("label_employment")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Own business"
                        error={error.source_of_wealth}
                        onChange={(e) =>
                          handleSourceOfWealthChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sow_own_business"
                      />
                      <StyledCustomControlLabel htmlFor="sow_own_business">
                        {t("label_own_business")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Investment"
                        error={error.source_of_wealth}
                        onChange={(e) =>
                          handleSourceOfWealthChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sow_investment"
                      />
                      <StyledCustomControlLabel htmlFor="sow_investment">
                        {t("label_investment")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Savings"
                        error={error.source_of_wealth}
                        onChange={(e) =>
                          handleSourceOfWealthChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                        id="sow_savings"
                      />
                      <StyledCustomControlLabel htmlFor="sow_savings">
                        {t("label_savings")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    <StyledCustomCheckboxWrapper affordability="true">
                      <StyledInput
                        customControl
                        type="checkbox"
                        name="Other"
                        error={error.source_of_wealth}
                        onChange={(e) =>
                          setSourceOfWealthOtherChecked(e.target.checked)
                        }
                        id="sow_other"
                      />
                      <StyledCustomControlLabel htmlFor="sow_other">
                        {t("label_other")}
                      </StyledCustomControlLabel>
                    </StyledCustomCheckboxWrapper>
                    {error.source_of_wealth && (
                      <InvalidFeedback>
                        {t(error.source_of_wealth)}
                      </InvalidFeedback>
                    )}
                  </ResetFormGroup>
                  <ResetFormGroup
                    className={sourceOfWealthOtherChecked ? "" : "hidden"}
                  >
                    {/*<label>{t("label_source_of_wealth_other")}</label>*/}
                    <StyledInput
                      cp
                      type="text"
                      error={error.source_of_wealth_other}
                      onChange={(e) => setSourceOfWealthOther(e.target.value)}
                      /* onBlur={handleErrorCheck} */
                    />
                    {error.source_of_wealth_other && (
                      <InvalidFeedback>
                        {t(error.source_of_wealth_other)}
                      </InvalidFeedback>
                    )}
                  </ResetFormGroup>
                  <ResetFormGroup className="mt-5">
                    <label>
                      {t(
                        "label_estimated_disposable_annual_income",
                        "label_estimated_disposable_annual_income"
                      )}
                    </label>
                    <StyledSelect
                      selecCenter
                      error={error.estimated_disposable_annual_income}
                      onChange={(e) =>
                        setEstimatedDisposableAnnualIncome(e.target.value)
                      }
                      /* onBlur={handleErrorCheck} */
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled></option>
                      <option value={isSESEK ? "99999" : "9999"}>
                        {isSESEK ? "0 - 99,999" : "0 - 9,999"}
                      </option>
                      <option value={isSESEK ? "199999" : "19999"}>
                        {isSESEK ? "100,000 - 199,999" : "10,000 - 19,999"}
                      </option>
                      <option value={isSESEK ? "299999" : "29999"}>
                        {isSESEK ? "200,000 - 299,999" : "20,000 - 29,999"}
                      </option>
                      <option value={isSESEK ? "499999" : "49999"}>
                        {isSESEK ? "300,000 - 499,999" : "30,000 - 49,999"}
                      </option>
                      <option value={isSESEK ? "699999" : "69999"}>
                        {isSESEK ? "500,000 - 699,999" : "50,000 - 69,999"}
                      </option>
                      <option value={isSESEK ? "999999" : "99999"}>
                        {isSESEK ? "700,000 - 999,999" : "70,000 - 99,999"}
                      </option>
                      <option value={isSESEK ? "1000000" : "100000"}>
                        {isSESEK ? "1000000+" : "100000+"}
                      </option>
                    </StyledSelect>
                    {error.estimated_disposable_annual_income && (
                      <InvalidFeedback>
                        {t(
                          error.estimated_disposable_annual_income,
                          error.estimated_disposable_annual_income
                        )}
                      </InvalidFeedback>
                    )}
                  </ResetFormGroup>
                  <ResetFormGroup>
                    <label>
                      {t("label_gambling_budget", "label_gambling_budget")}
                    </label>

                    <StyledInput
                      cp
                      type="number"
                      error={error.budget}
                      onChange={(e) => setBudget(e.target.value)}
                      /* onBlur={handleErrorCheck} */
                    />
                    {error.budget && (
                      <InvalidFeedback>{t(error.budget)}</InvalidFeedback>
                    )}
                  </ResetFormGroup>
                </ResetWrapper>
                <StyledCustomCheckboxWrapper affordability="true">
                  <StyledInput
                    customControl
                    type="checkbox"
                    name="edd_confirm_checkbox"
                    error={error.edd_confirm_checkbox}
                    onChange={(e) => setEddConfirmCheckbox(e.target.checked)}
                    /* onBlur={handleErrorCheck} */
                    id="edd_confirm_checkbox"
                  />
                  <InvalidFeedback>
                    {error.edd_confirm_checkbox}
                  </InvalidFeedback>
                  <StyledCustomControlLabel htmlFor="edd_confirm_checkbox">
                    {t(
                      "label_edd_confirm_information",
                      "label_edd_confirm_information"
                    )}
                  </StyledCustomControlLabel>
                </StyledCustomCheckboxWrapper>
                <StyledSectionTimeLimit
                  padding={plcConfig ? "pb-5" : "pb-5 px-3"}
                  border={plcConfig ? "border-bottom" : "border-0"}
                  className={"mt-5"}
                >
                  <StyledButton
                    pub={skin === "pub"}
                    limit
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                      occupation.length < 3 ||
                      (sourceOfFunds.length === 0 &&
                        sourceOfFundsOther.trim().length < 3) ||
                      estimatedDisposableAnnualIncome === "" ||
                      !eddConfirmCheckbox ||
                      budget === ""
                    }
                  >
                    {t("label_submit", "label_submit")}
                  </StyledButton>
                </StyledSectionTimeLimit>{" "}
              </div>
            )}
            {i18n.exists("label_aff_link") && (
              <TabContent rac="true">
                <TransactionDescription
                  dangerouslySetInnerHTML={{
                    __html: t("label_aff_info"),
                  }}
                ></TransactionDescription>

                <video
                  src={t("label_aff_link")}
                  preload="metadata"
                  width="100%"
                  height="100%"
                  controls
                ></video>
              </TabContent>
            )}
          </AccountContainer>
        </PageContentWrapper>
      </PageContent>
    </>
  );
};

export default Verification;
