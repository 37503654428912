import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MUTATION_UPDATE_DATA_LINK} from "../graphql/mutations";
import { useApolloClient } from "@apollo/client";

const EcospendRedirect = ({ match }) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient()
  const {
    params: { ecStatus, ecDataLinkID, ecConsentID, ecBankRererenceID },
  } = match;
  // const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  // const language = i18n.language;
  // console.log(ecStatus, ecDataLinkID, ecConsentID, ecBankRererenceID)

  useEffect(() => {
      async function updateDataLink() {
          // console.log(ecStatus, ecDataLinkID, ecConsentID, ecBankRererenceID)
          const { loading, errors } = await apolloClient.mutate({
              mutation: MUTATION_UPDATE_DATA_LINK,
              variables: {
                  consentId: ecConsentID,
                  bankReferenceId: ecBankRererenceID,
                  dataLinkId: ecDataLinkID,
              },
              errorPolicy: "all",
          });
          if (loading) return "Loading ...";
          if (errors) {
              return false;
          }

          //when its not error redirect
          window.opener && window.opener.postMessage(
              JSON.stringify({
                  stepEcospend: "close"
              }),
              window.location.origin
          )

          // console.log(window.opener)
          //if ecospend link is opened in new window (direct link), redirect to index page
          if(!window.opener) {
              window.location.replace("/")
          }

          return true;
      }

      // console.log(ecStatus)

      if(ecStatus === "Authorised") {
          updateDataLink().then(() => {
              console.log("data link updated")
              window.opener &&
              window.close();
          })
      } else {
          // console.log("send post message")
          window.opener && window.opener.postMessage(
              JSON.stringify({
                  stepEcospend: "close"
              }),
              window.location.origin
          );
          window.opener &&
          window.close();
          // console.log(window.opener)
          //if ecospend link is opened in new window (direct link), redirect to index page
          if(!window.opener) {
              window.location.replace("/")
          }
          // window.top && window.top.postMessage(
          //     JSON.stringify({
          //         stepEcospend: "close"
          //     }),
          //     window.location.origin
          // );
      }

  }, []);

  return (
    <>
      <div className="idin-redirect message text-center mt-5" style={{position: "absolute", color: "white", bottom: "5vh", margin: "auto", width: "100%", left: 0, right: 0}}>
        {t("label_please_wait", "label_please_wait")}
      </div>
    </>
  );
};

export default EcospendRedirect;
